import { isDevOrPreviewSite } from '../../utils/general'
import { F_API_KEY, F_CUSTOM_DOMAIN, F_EDIT_MODE, F_PWD_PROTECTION } from './constants'

const PRODUCT_ID_PROD = 'prod_HXjN9aML8fXChn'
const PRODUCT_ID_DEV = 'prod_HXjPwsEONzD5oI'

const PRICE_ID_YEARLY_PROD = 'price_1KfV3kKnX1nnNv6IwPx129d6'
const PRICE_ID_YEARLY_DEV = 'price_1KfVJZKnX1nnNv6IkJoWrMxg'

const PRICE_ID_MONTHLY_PROD = 'price_1KfV2zKnX1nnNv6ICHQPi5Qp'
const PRICE_ID_MONTHLY_DEV = 'price_1KfVLOKnX1nnNv6IRf8vpcvK'

const PADDLE_PRICE_ID_YEARLY_PROD = 761271
const PADDLE_PRICE_ID_YEARLY_DEV = 20460

const PADDLE_PRICE_ID_MONTHLY_PROD = 761270
const PADDLE_PRICE_ID_MONTHLY_DEV = 20459

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD
const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD

const PRO_PLAN = {
  id: PRODUCT_ID,
  label: 'Solo',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 156,
      paddleLink: 'https://buy.paddle.com/product/761271',
      paddleId: PADDLE_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 18,
      paddleLink: 'https://buy.paddle.com/product/761270',
      paddleId: PADDLE_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 75,
  customDomainsLimit: 5,
  projectLimit: 5,
  visitorLimit: 100000,
  bandwidthLimit: 50,
  features: {
    [F_CUSTOM_DOMAIN]: true,
    [F_PWD_PROTECTION]: true,
    [F_EDIT_MODE]: true,
    [F_API_KEY]: true
  }
}

export default PRO_PLAN

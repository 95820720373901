import { isDevOrPreviewSite } from '../../utils/general'

const PRODUCT_ID_PROD = 'prod_ILvSTMdyhRsuO5'
const PRODUCT_ID_DEV = 'prod_IJoO4AhlOWDY7z'

const PRICE_ID_QUARTERLY_PROD = 'price_1IrqdkKnX1nnNv6IeE9GjFlc'
const PRICE_ID_QUARTERLY_DEV = 'price_1IrrX9KnX1nnNv6I9K5sEdnA'

const PADDLE_PRICE_ID_QUARTERLY_PROD = 654612
const PADDLE_PRICE_ID_QUARTERLY_DEV = 20463

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_QUARTERLY = isDevOrPreviewSite() ? PRICE_ID_QUARTERLY_DEV : PRICE_ID_QUARTERLY_PROD
const PADDLE_PRICE_ID_QUARTERLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_QUARTERLY_DEV : PADDLE_PRICE_ID_QUARTERLY_PROD

export const QUARTERLY = '3-months'

const PRO_STUDENT = {
  id: PRODUCT_ID,
  label: 'Student',
  prices: {
    [QUARTERLY]: {
      id: PRICE_ID_QUARTERLY,
      value: 18,
      paddleLink: 'https://buy.paddle.com/product/654612',
      paddleId: PADDLE_PRICE_ID_QUARTERLY
    }
  },
  fileSizeLimit: 5,
  customDomainsLimit: 1,
  projectLimit: 3,
  visitorLimit: 500000,
  bandwidthLimit: 5,
  features: {}
}

export default PRO_STUDENT

import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import HomeHeader from '../components/HomePageHeader'
import Footer from '../components/Footer'
import StyledDropzone from '../components/StyledDropzone'
import SelectedFile from '../components/SelectedFile'
import { reCAPTCHA_CLIENT } from '../constants'
import { supportUpload } from '../services/support-upload'

const schema = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  notes: Yup.string()
})

const STAGE_INITIAL = 0
const STAGE_SUCCESS = 1
const STAGE_ERROR = 2

const SupportUpload = ({ googleReCaptchaProps }) => {
  const [stage, setStage] = useState(STAGE_INITIAL)
  const [isUploading, setIsUploading] = useState(false)

  const onSubmit = async ({ email, notes, fileSelected }) => {
    setIsUploading(true)

    const formData = new FormData()
    formData.append('email', email.toLowerCase())
    formData.append('file', fileSelected)
    formData.append('notes', notes)

    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('support_upload')
    const headers = { 'x-recaptcha': recaptchaValue }

    supportUpload(formData, headers)
      .then((res) => {
        if (res.data.success) {
          setStage(STAGE_SUCCESS)
          // reset form?
        } else {
          setStage(STAGE_ERROR)
        }
      })
      .catch(() => {
        setStage(STAGE_ERROR)
      })
      .finally(() => setIsUploading(false))
  }

  return (
    <div className="full-height">
      <HomeHeader />
      <div className="container">
        <h1 className="mt-5 bold">Support File Upload</h1>
        <p>Please upload your file below and we will get back to you shortly.</p>
        <div className="box-100 mt-4 mb-5 p-3">
          {stage === STAGE_ERROR && !isUploading && (
            <Alert variant="danger">
              <h5>Oops, something went wrong. Please try again.</h5>
            </Alert>
          )}
          {stage === STAGE_SUCCESS && (
            <div>
              <p>Thank you, your file was submitted successfully.</p>
              <Button onClick={() => setStage(STAGE_INITIAL)}>Done</Button>
            </div>
          )}
          {stage !== STAGE_SUCCESS && (
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{}}>
              {({ handleSubmit, handleChange, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Control
                    className="mt-2"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={isUploading}
                  />
                  <ErrorMessage name="email" component="div" className="validation-error" />
                  <Form.Control
                    className="mt-3"
                    name="notes"
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Whats the issue?"
                    value={values.notes}
                    onChange={handleChange}
                    disabled={isUploading}
                  />
                  <ErrorMessage name="code" component="div" className="validation-error" />
                  {!values.fileSelected && (
                    <StyledDropzone onDrop={(files) => setFieldValue('fileSelected', files[0])} hideTemplate />
                  )}
                  {values.fileSelected && (
                    <SelectedFile
                      file={values.fileSelected}
                      onRemove={() => setFieldValue('fileSelected', undefined)}
                      leftAlign
                    />
                  )}
                  <Button className="me-auto mt-3 float-right" type="submit" disabled={isUploading}>
                    {isUploading ? <Spinner animation="border" variant="light" size="sm" /> : 'Submit'}
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

const SupportUploadWithReCaptcha = withGoogleReCaptcha(SupportUpload)

const ReCaptchaWrapper = () => (
  <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA_CLIENT}>
    <SupportUploadWithReCaptcha />
  </GoogleReCaptchaProvider>
)

export default ReCaptchaWrapper

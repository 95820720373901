import React, { useState } from 'react'
import styles from '../CustomDomainModal.module.css'
import { OptionButton } from '../CustomDomainModal'
import CTAButton from 'components/CTAButton/CTAButton'
import { DNS_SETTINGS } from '../constants/strings'
import {Button, Spinner} from 'react-bootstrap'

const DNSSetting = ({ formik, onNext, onPrevious, handleCheckEntri, setActiveStep }) => {
  const [loading, setLoading] = useState(false)
  const { values } = formik
  const isOwner = !!(values.dnsAddress.selected === 'yes')
  const isRootDomain = !!!values.dnsAddress.subdomain
  const domain_value = values.dnsAddress.domain
  const subdomain = values.dnsAddress.subdomain
  const fulldomainAddress = !!subdomain ? `${subdomain}.${domain_value}` : domain_value

  const handleNext = async () => {
    const payload = {
      domain: fulldomainAddress,
      owner: isOwner,
      replaceWWW: isRootDomain
    }
    if (values.dnsAddress.selected === 'yes') {
      setLoading(true)
      const response = await handleCheckEntri(payload, formik)
      if (response === 'manual') {
        formik.setFieldValue(`wizard.selected`, 'no')
        setActiveStep(3)
      } else if (response === 'automatic') {
        setLoading(false)
        onNext()
      } else {
        setLoading(false)
      }
    } else if (values.dnsAddress.selected === 'no') {
      setLoading(false)
      onNext()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.subHeading}>
        <div className={styles.title}>{DNS_SETTINGS.SUBHEADING}</div>
        <div className={styles.subTitle}>{DNS_SETTINGS.DESCRIPTION}</div>
      </div>

      <div className={styles.optionCon}>
        <OptionButton
          actionDesc={'I have access to the DNS settings'}
          actionlabel={'Yes'}
          active={values.dnsAddress.selected === 'yes'}
          selectOption={() =>
            formik.setFieldValue(`dnsAddress.selected`, values.dnsAddress.selected === 'yes' ? null : 'yes')
          }
        />
        <OptionButton
          actionDesc={"I don't have access to the DNS settings"}
          actionlabel={'No'}
          selectOption={() =>
            formik.setFieldValue(`dnsAddress.selected`, values.dnsAddress.selected === 'no' ? null : 'no')
          }
          active={values.dnsAddress.selected === 'no'}
        />
      </div>
      <div className={styles.btnContainer}>
        <Button
          variant="outline-primary"
          onClick={onPrevious}
          size="small"
          showLeftArrow={true}
          showArrow={false}
          leftArrowClass={styles.backArrow}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          size="small"
          disabled={loading || !values.dnsAddress.selected}
          className={styles.forwardButton}
          loading={loading}
        >
          Next {loading && <Spinner animation="border" variant="light" size="sm" />}
        </Button>
      </div>
    </div>
  )
}

export default DNSSetting

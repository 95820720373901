import React, { useState } from 'react'
import { showUpgradeModal } from '../../Manage/actions'
import ProPlans from './index'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { SOURCE_LOGGED_IN } from '../../constants'

const ProPlansModal = ({ email, showProPlans, dispatch }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="upgrade-modal-backdrop"
      size="xl"
      onHide={() => dispatch(showUpgradeModal(false))}
      show={showProPlans}
      centered
    >
      <div className={`plans-modal text-center ps-5 pe-5 pe-md-0 ps-md-0`}>
        <div
          className="cursor-pointer position-absolute"
          onClick={() => dispatch(showUpgradeModal(false))}
          style={{ top: 16, right: 20 }}
        >
          <img src="/assets/icons/cancel_black.svg" height={20} />
        </div>
        <h1 className="bold mt-5 font-38 color-purple">Upgrade for more</h1>
        <ProPlans
          className="w-100 p-5"
          email={email}
          onPaymentClickCallback={() => dispatch(showUpgradeModal(false))}
          onPaymentHideCallback={() => dispatch(showUpgradeModal(false))}
          source={SOURCE_LOGGED_IN}
          showModal
          hideFreeCard
        />
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = ({ manage }) => ({
  showProPlans: manage.upgradeModalVisible
})

export default connect(mapStateToProps, mapDispatchToProps)(ProPlansModal)

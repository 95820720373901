import React from 'react'
import styles from './ScaleCounter.module.css'
import CountUp from 'react-countup'

const ScaleCounter = () => (
  <div className={styles.container}>
    <div className={`indie-flower-font color-purple ${styles.title}`}>Built to scale</div>
    <CountUp className={styles.number} end={1000000} separator="," useEasing={true} duration={2} />
    <span className={styles.number}>+</span>
    <div className={styles.subtitle}>
      <b>Pageviews</b> across all links
    </div>
  </div>
)

export default ScaleCounter

import React from 'react'
import FAQ from './components/faq'
import HomeHeader from '../components/HomePageHeader'
import Footer from '../components/Footer'
import Email from './components/Email'
import { Button } from 'react-bootstrap'

const Terms = () => (
  <div>
    <div className="mt-5">
      <h3 className="bold">Legals</h3>
      <h4 className="mt-5 ms-3">
        <a className="bold" href="/termsofservice.html" target="_blank">
          <u>Terms of Service</u>
        </a>
      </h4>
    </div>
  </div>
)

const ReportAbuse = () => (
  <div>
    <h2 className="bold mt-5 mb-4">Report abuse</h2>
    <p>
      For DMCA takedown notices or to report a illegal, fraudulent, phishing or copyright infringing content please
      email us.
    </p>
    {/*
  <Button variant="outline-primary">Report abuse</Button>
*/}
  </div>
)

const Help = () => (
  <div className="body-bg full-height">
    <HomeHeader />
    <div className="container">
      <div className="container-help">
        <Email />
        <ReportAbuse />
        <FAQ />
        <Terms />
      </div>
    </div>
    <Footer />
  </div>
)

export default Help

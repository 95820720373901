import { addSegment } from 'services/email-list'

function getTodayDateForSendy() {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const clickedCustomDomain = async () => {
  const data = {
    customFields: {
      clicked_connect_custom_domain: true,
      connect_custom_domain_date: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const uploadedFileTooBig = async () => {
  const data = {
    customFields: {
      uploaded_file_too_big: true,
      uploaded_file_too_big_date: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const openPricingPopup = async () => {
  const data = {
    customFields: {
      opened_pricing_popup: true,
      opened_pricing_popup_date: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const uploadedMoreThanOneProject = async () => {
  const data = {
    customFields: {
      uploaded_more_than_one_project: true,
      uploaded_more_one_project_date: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const abandonedCheckout = async () => {
  const data = {
    customFields: {
      abandoned_checkout: true,
      abandoned_checkout_date: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const refreshLogin = async () => {
  //TODO: disabling to reduce sendy load
  return
  const data = {
    customFields: {
      last_logged_in: getTodayDateForSendy()
    }
  }
  await addSegment(data)
}

export const trackUploadFileCount = async () => {
  const data = {
    customFields: {
      uploaded_file: true
    }
  }
  await addSegment(data)
}

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { transactionFailed, transactionInit, transactionProcessing, TRANSACTION_INIT } from '../../../Pricing/actions'
import { LoadingBox } from '../../LoadingBox/LoadingBox'
import { createSubscription } from '../../../services/payment'
import { setShowPaymentModal } from 'actions'
import * as Sentry from '@sentry/react'
import { PLANS_ID } from '../../../constants/plans'
import { calcUpgradeTime, trackSubscriptionUpgrade, trackSubscriptionUpgradeInfo } from '../../../utils/analytics'

const getRedirectUrl = ({ customerId, isTrialUpgrade, subscriptionId, paymentGateway }) =>
  `/pro-welcome?id=${customerId}&skipq=${isTrialUpgrade}&sid=${subscriptionId}&payment=${paymentGateway}`

const isTrialUpgrade = window.location.pathname.includes('manage')

const PaddlePay = ({
  transactionState,
  dispatch,
  history,
  priceId,
  email,
  studentData = {},
  paddleId,
  source,
  productId,
  period,
  accountCreated
}) => {
  const [checkoutLoader, setCheckoutLoader] = useState(true)
  const [paddleContainer, setPaddleContainer] = useState(false)
  const Paddle = window.Paddle
  useEffect(() => {
    if (transactionState === TRANSACTION_INIT) {
      setPaddleContainer(false)
      setCheckoutLoader(true)
      openCheckout({ email, paddleId })
    }
  }, [transactionState, paddleId])

  const loadCallback = () => {
    setCheckoutLoader(false)
    setPaddleContainer(true)
  }

  const getOrderDetails = (checkoutId) => {
    return new Promise((resolve, reject) => {
      Paddle.Order.details(checkoutId, (data) => {
        if (!data.success && data.error) reject(data.error)
        resolve(data.order)
      })
    })
  }

  const checkoutComplete = (data) => {
    dispatch(transactionProcessing())
    if (data.checkout.completed) {
      let userEmail
      setPaddleContainer(false)

      getOrderDetails(data.checkout.id)
        .then((order) => {
          userEmail = email || order.customer.email
          return createSubscription({
            email: userEmail.toLowerCase(),
            paymentMethodId: order.order_id,
            priceId,
            studentData,
            paymentGateway: 'PADDLE',
            source,
            productId
          })
        })
        .then((subscription) => {
          const { subscriptionStatus, customerId, subscriptionId, checkoutId } = subscription.data
          if (subscriptionStatus === 'active') {
            // affiliate tracking
            if (window.fpr) {
              window.fpr('referral', { email: userEmail })
            }

            dispatch(transactionInit())
            dispatch(setShowPaymentModal({ showPaymentModal: false, upgradePlanId: null }))
            trackSubscriptionUpgrade(
              email,
              PLANS_ID[productId]?.label,
              PLANS_ID[productId]?.prices[period]?.value,
              period,
              checkoutId,
              source
            )
            trackSubscriptionUpgradeInfo(email, calcUpgradeTime(accountCreated))
            history.push(getRedirectUrl({ customerId, isTrialUpgrade, subscriptionId, paymentGateway: 'PADDLE' }))
          } else {
            throw new Error('Failed While Creating Subscription')
          }
        })
        .catch((error) => {
          console.error(error)
          setPaddleContainer(false)
          dispatch(transactionFailed())
          Sentry.captureException(error, {
            tags: {
              section: 'paddle-error'
            }
          })
        })
    } else {
      setPaddleContainer(false)
      dispatch(transactionFailed())
      Sentry.captureException('UNKNOWN_PADDLE_ERROR', {
        tags: {
          section: 'paddle-error'
        }
      })
    }
  }

  const openCheckout = ({ email, paddleId }) => {
    Paddle.Checkout.open({
      method: 'inline',
      product: paddleId,
      email,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: 'checkout-container',
      frameInitialHeight: 350,
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
      successCallback: checkoutComplete,
      loadCallback
    })
  }

  return (
    <div>
      <LoadingBox className={checkoutLoader ? 'd-block' : 'd-none'} message="Loading Paddle Checkout" />
      <div className={`checkout-container ${paddleContainer ? 'd-block' : 'd-none'}`} />
      <br />
    </div>
  )
}

export default withRouter(PaddlePay)

import React from 'react'
import Footer from '../components/Footer'
import HomeHeader from '../components/HomePageHeader'
import { Button } from 'react-bootstrap'
import Feature from '../components/Feature'
import { Helmet } from 'react-helmet'

const Affiliates = () => (
  <>
    <Helmet>
      <title>Tiiny Host - Become a trusted affiliate</title>
      <meta property="og:title" content="Tiiny Host - Become our trusted affiliate" />
      <meta property="og:description" content="Become our trusted affiliate. Earn 20% of all referrals." />
      <meta name="description" content="Become our trusted affiliate. Earn 20% of all referrals." />
    </Helmet>
    <HomeHeader />
    <div className="container text-start mb-5 pb-5">
      <div className="mt-5 w-75 ms-auto me-auto text-center mb-5">
        <div style={{ fontSize: '136px' }}>🤝</div>
        <h1 className="bold gradient-text" style={{ fontSize: '60px' }}>
          Become our trusted affiliate partner
        </h1>
        <p className="op-8 font-26 mt-5 font-weight-light">
          <b>Receive 20% of all subscriptions</b> converted through your unique referral link per month.
        </p>
        <Button className="mt-3" href="https://tiiny.firstpromoter.com" target="_blank">
          Become an affiliate
        </Button>
      </div>
      <Feature
        title="Introduce your audience to a fast growing product loved by thousands worldwide"
        titleClassName="font-weight-light line-height-m feature-title-border"
        descClassName="font-20 font-weight-light line-height-m"
        svg="/assets/icons/growth.png"
      >
        <ul className="mt-4">
          <li>Over 50,000 websites uploaded</li>
          <li>Loved by developers, designers, freelancers & agencies</li>
          <li>Support, assets and marketing tips provided</li>
          <li>60-day cookie life</li>
        </ul>
      </Feature>
      <Feature
        title="Earn a stable recurring revenue every month"
        titleClassName="font-weight-light line-height-m feature-title-border"
        text="Diversify your income by earning commission from web hosting, a reliable source of income."
        descClassName="font-20 font-weight-light line-height-m"
        svg="/assets/icons/dollar.png"
      />
      <Feature
        title="Easily track all of your referrals"
        titleClassName="font-weight-light feature-title-border"
        text="We use First Promoter to provide you with an easy to use dashboard to track your referrals and commission."
        descClassName="font-20 font-weight-light line-height-m"
        svg="/assets/icons/pie-chart.png"
      />
    </div>
    <h2 className="bold gradient-text" style={{ fontSize: '40px' }}>
      Boost your income
    </h2>
    <Button className="mt-5 mb-5" href="https://tiiny.firstpromoter.com" target="_blank">
      Become an affiliate
    </Button>
    <br />
    <a href="/affiliateterms.html" target="_blank">
      Terms & Conditions apply
    </a>
    <br />
    <br />
    <Footer />
  </>
)

export default Affiliates

import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

import styles from './CTAButton.module.css'

const CTAButton = ({
  className,
  label,
  onClick,
  size = 'normal',
  variant = 'primary',
  showArrow = true,
  showLeftArrow,
  disabled = false,
  loading = false
}) => (
  <Button
    className={`${styles.button} ${styles[size]} ${styles[variant]} ${className}`}
    onClick={onClick}
    disabled={disabled}
    variant="outline-primary"
  >
    {loading ? (
      <Spinner animation="border" size="sm" />
    ) : (
      <>
        {showLeftArrow && <span className={`${styles.arrow}`}>&larr;</span>}
        {label}
        {showArrow && <span className={styles.arrow}>&rarr;</span>}
      </>
    )}
  </Button>
)

export default CTAButton

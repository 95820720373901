/** Some timezone to show User right currency values.
 * This might not be optimal but user is will be able to choose currency from a dropdown anyway
 * */

/** An other way of doing it, would be using longitude / latitude to get a country code and using an API to get currency and price from country code
 * */

export const timeZones = {
  EU: [
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Madrid',
    'Europe/Rome',
    'Europe/Brussels',
    'Europe/Vienna',
    'Europe/Stockholm',
    'Europe/Zurich',
    'Europe/Warsaw',
    'Europe/Prague',
    'Europe/Copenhagen',
    'Europe/Luxembourg',
    'Europe/Amsterdam',
    'Europe/Bratislava',
    'Europe/Budapest',
    'Europe/Ljubljana',
    'Europe/Malta',
    'Europe/Vilnius',
    'Europe/Riga',
    'Europe/Tallinn',
    'Europe/Helsinki',
    'Europe/Athens',
    'Europe/Sofia',
    'Europe/Bucharest',
    'Europe/Nicosia',
    'Europe/Lisbon',
    'Atlantic/Canary',
    'Atlantic/Faroe',
    'Atlantic/Madeira'
  ],
  UK: [
    'Europe/London',
    'Atlantic/Bermuda',
    'Atlantic/Stanley',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Indian/Chagos',
    'America/Cayman',
    'Europe/Gibraltar',
    'America/Montserrat',
    'Pacific/Pitcairn',
    'Atlantic/St_Helena',
    'Atlantic/South_Georgia',
    'America/Grand_Turk',
    'Europe/Guernsey',
    'Europe/Jersey',
    'Europe/Isle_of_Man'
  ],
  US: [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
    'America/Phoenix',
    'America/Indiana/Indianapolis',
    'America/Indiana/Marengo',
    'America/Indiana/Vevay',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Puerto_Rico',
    'Pacific/Pago_Pago',
    'Pacific/Guam',
    'Pacific/Wake',
    'Pacific/Midway',
    'Pacific/Johnston'
  ],
  IND: ['Asia/Kolkata', 'Asia/Calcutta']
}

import React from 'react'
import { Form, ButtonGroup, ToggleButton } from 'react-bootstrap'
import UpgradeBadge from 'components/UpgradeBadge/UpgradeBadge'

const SiteOption = ({
  label,
  desc,
  icon,
  disabled,
  trial,
  checked,
  onChange,
  children,
  toggleOptions,
  toggleValue,
  onToggleChange,
  onUpgrade
}) => (
  <div className="mt-3">
    <div className="flex w-100">
      <div className="flex-grow-1 d-flex">
        <img className="me-3 mt-1 op-6" src={`assets/icons/${icon}`} height={20} />
        <div>
          <div>
            {label}
            <UpgradeBadge show={trial} onUpgrade={onUpgrade} />
          </div>
          <div className="font-small grey">{desc}</div>
        </div>
      </div>
      {toggleOptions ? (
        <ButtonGroup>
          {toggleOptions.map((toggle, idx) => (
            <ToggleButton
              key={idx}
              id={`mode-${toggle.value}`}
              type="radio"
              variant={'outline-success'}
              name="toggle"
              value={toggle.value}
              checked={toggleValue === toggle.value}
              onChange={() => onToggleChange(toggle.value)}
            >
              {toggle.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      ) : (
        <Form.Check
          className="text-align-end"
          checked={checked}
          type="switch"
          onChange={onChange}
          disabled={trial || disabled}
        />
      )}
    </div>
    {checked && children}
  </div>
)

export default SiteOption

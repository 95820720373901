import React, { useState } from 'react'
import { Button, Form, InputGroup, Spinner, Alert } from 'react-bootstrap'
import { cancelSubscription } from '../../../services/manage'
import CustomTooltip from '../../CustomTooltip'
import { copyToClipboard, getSubdomainAndTLD } from 'utils/general'

const OPTIONS = [
  'I was working on a one-off project and only needed tiiny host for a month',
  'Tiiny Host lacked a feature I was looking for',
  "There were technical issues / I wasn't able to link a custom domain",
  'It was too expensive for me',
  'Something else',
  'My link was blocked whilst sharing on social platforms'
]

const CancellationStage = ({ sites = [], onUpgrade, onBack, onHide, setHasError }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [otherText, setOtherText] = useState('')

  const urlParams = getSubdomainAndTLD({ site: { subdomain: sites[0]?.subdomain } })
  const redirect_url = `${urlParams?.subdomain}.tiiny.xyz/?d=${urlParams?.tld?.replace(/^\./, '')}`
  const learn_more =
    'https://helpdesk.tiiny.host/en/article/your-tiiny-host-link-is-blocked-whilst-sharing-it-on-outlookfacebooklinkedin-or-other-apps-19nndl6/'

  const onSubmit = () => {
    setLoading(true)
    cancelSubscription({ value, otherText })
      .then(() => {
        window.location.reload()
        onHide()
      })
      .catch((e) => {
        console.error(e)
        setHasError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <h1 className="modalTitle">Cancel your subscription</h1>
      <div className="mb-3 grey">Please tell us why you would like to cancel your subscription:</div>
      <Form>
        <div className="mb-3">
          <Form.Group controlId="cancel-reason">
            <Form.Control as="select" value={value} onChange={(e) => setValue(e.target.value)}>
              <option value="">Please select a reason</option>
              {OPTIONS.map((option, index) => (
                <option key={index} value={String.fromCharCode(65 + index)}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {['B', 'C', 'E'].includes(value) && (
            <InputGroup className="input-group">
              <Form.Control
                className="mt-4"
                onChange={(t) => setOtherText(t?.target?.value)}
                placeholder="Please explain in detail..."
                autoFocus={true}
                type="text"
              />
            </InputGroup>
          )}
          {value === 'D' && (
            <Alert className="mt-2 bold" variant="warning">
              Save money and{' '}
              <a onClick={() => onUpgrade(false)} className="pl-4 link color-purple">
                change plan →
              </a>
            </Alert>
          )}
          {value === 'F' && !!sites.length && (
            <Alert className="mt-2" variant="warning">
              <b>
                Have you tried sharing with our social links? (
                <a href={learn_more} target="_blank" rel="noopener noreferrer" className="text-black">
                  Learn more
                </a>
                )
              </b>
              <div className="d-flex mt-2">
                E.g.
                {sites[0] && (
                  <>
                    <a href={`https://${redirect_url}`} target="_blank" rel="noopener noreferrer" className="ms-2">
                      {redirect_url}
                    </a>
                    <CustomTooltip label="Copy">
                      <img
                        src="/assets/icons/copy.png"
                        height={16}
                        width={16}
                        onClick={() => copyToClipboard(`https://${redirect_url}`)}
                        className="d-inline-block cursor-pointer mx-2"
                        alt="Copy"
                      />
                    </CustomTooltip>
                  </>
                )}
              </div>
            </Alert>
          )}
        </div>
        <Button
          disabled={
            loading ||
            !((value && !['B', 'C', 'E'].includes(value)) || (['B', 'C', 'E'].includes(value) && otherText?.length > 5))
          }
          className="float-right"
          onClick={onSubmit}
          variant="purple"
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Cancel account'}
        </Button>
        <Button className="float-right mx-2" variant="outline-primary" onClick={onBack}>
          Back
        </Button>
      </Form>
    </div>
  )
}

export default CancellationStage

import React from 'react'
import CustomTooltip from '../CustomTooltip'
import styles from './LinkButton.module.css'

const LinkButton = ({ label, onClick, disabled = false, tooltip = '', icon, className = '' }) => {
  const buttonContent = (
    <>
      {icon && <img src={icon} alt="label" className={styles.buttonIcon} />}
      {label && <span className={styles.buttonText}>{label}</span>}
    </>
  )

  const button = (
    <button className={`${styles.linkButton} ${className}`} onClick={onClick} disabled={disabled}>
      {buttonContent}
    </button>
  )

  if (disabled && tooltip) {
    return <CustomTooltip label={tooltip}>{button}</CustomTooltip>
  }

  return button
}

export default LinkButton

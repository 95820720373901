const FAQS = [
  {
    q: 'What type of file formats do you support?',
    a: 'We currently support HTML, ZIP, PHP, PDF files, and almost all popular image, raw image, document, powerpoint and excel file formats (more coming soon).<br /> <br />If you\'re uploading a website, we support static sites.<br /><br />A static site is composed of frontend only static files such as HTML, JavaScript and images (jpeg, png, svg etc). These files should be uploaded as a zip file.<br /><br />View our full list <a href="https://helpdesk.tiiny.host/en/article/what-type-of-file-formats-do-you-support-na0784/?bust=1730236719643">here</a>'
  },
  {
    q: 'How long will my link be online on your free plan?',
    a: 'Links stay online indefinitely on our free plan as long as you log into your account once every 3 months. We do not remove your links after a period of time as long as your account remains active.'
  },
  {
    q: 'Do you support WordPress or similar CMS?',
    a: 'No we do not. However, you can "static-ify your WordPress website through plugins: <a target="_blank" href="https://www.youtube.com/watch?v=1A5y4fezB5A">See video</a>'
  },
  {
    q: 'Do you support PHP?',
    a: 'Yes, we  host PHP files and PHP app zip files.'
  },
  {
    q: 'Are my links publicly accessible',
    a: 'Yes, all content you upload is publicly available on your link. If you prefer to keep it private, we recommend adding a password to your link through one of our subscription plans.'
  },
  {
    q: 'Do you support e-commerce sites?',
    a: 'We do not in the traditional CMS way (WordPress, Joomla etc), but we do support static e-commerce sites.'
  },
  {
    q: "What's the fastest way to reach you in case I need help?",
    a: "Send us a message via the chat icon in the bottom right-hand corner or an email at support@tiiny.host. We're quick to respond and always happy to help. Our goal is to make sharing to the web as simple as possible."
  },
  {
    q: 'How does your 7 day money back guarantee work?',
    a: "We have many happy customers. However, if for some reason after you upgrade to one of our paid plans you decide that it's not for you, then just reach out to our friendly support within 7 days and we'll be happy to give you a full refund."
  },
  {
    q: 'Can I cancel at anytime?',
    a: 'Of course! If you no longer need us, just cancel your subscription via your control panel. We process subscriptions through Stripe - which also manages thousands of other subscription services.'
  },
  {
    q: 'What happens after I cancel?',
    a: "Your sites will remain live until the end of your billing period. After this time, we'll completely remove them along with your account from our servers."
  }
]

export default FAQS

import React, { useState } from 'react'
import styles from '../CustomDomainModal.module.css'
import { OptionButton } from '../CustomDomainModal'
import CTAButton from 'components/CTAButton/CTAButton'
import { domainErrorMessage } from '../../CustomDomainTable/index'
import { WIZARD } from '../constants/strings'
import GetDomain from './GetDomain'
import { Button } from 'react-bootstrap'

const SetUpWizard = ({ formik, onNext, onPrevious, onSelectEntri, onFail, setActiveStep }) => {
  const [loading, setLoading] = useState(false)
  const { values } = formik

  const isRootDomain = !!!values.dnsAddress.subdomain

  const handleSetUpWithEntri = async () => {
    if (values.wizard.selected === 'yes') {
      setLoading(true)
      try {
        const { domain, token, cert, cfName } = values.dnsRecords.data
        onSelectEntri(domain, token, cert, cfName, isRootDomain)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        onFail(domainErrorMessage)
      }
    } else if (values.wizard.selected === 'no') {
      setLoading(false)
      onNext()
    }
  }
  return (
    <div className={styles.container}>
      {values.dnsAddress.selected === 'no' ? (
        <GetDomain
          formik={formik}
          onPrevious={onPrevious}
          onNext={onNext}
          setActiveStep={setActiveStep}
          onMessage={onFail}
        />
      ) : (
        <>
          <div className={styles.subHeading}>
            <div className={styles.title}>{WIZARD.SUBHEADING}</div>
            <div className={styles.subTitle}>{WIZARD.DESCRIPTION}</div>
          </div>
          <div className={styles.optionCon}>
            <OptionButton
              actionDesc={'Simple setup'}
              actionlabel={'Automatically'}
              selectOption={() => {
                formik.setFieldValue(`wizard.selected`, values.wizard.selected === 'yes' ? null : 'yes')
              }}
              active={values.wizard.selected === 'yes'}
            />
            <OptionButton
              actionDesc={'Advanced set-up'}
              actionlabel={'Manually'}
              selectOption={() => {
                formik.setFieldValue(`wizard.selected`, values.wizard.selected === 'no' ? null : 'no')
              }}
              active={values.wizard.selected === 'no'}
            />
          </div>
          <div className={`${styles.btnContainer}`}>
            <Button
              variant="outline-primary"
              onClick={onPrevious}
              size="small"
              showLeftArrow={true}
              showArrow={false}
              leftArrowClass={styles.backArrow}
            >
              Back
            </Button>
            <Button
              onClick={handleSetUpWithEntri}
              size="small"
              disabled={!values.wizard.selected}
              className={styles.forwardButton}
              loading={loading}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default SetUpWizard

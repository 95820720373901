import api from '../utils/api'
import { API_ROOT } from '../constants'

export const createSubscription = ({
  email,
  name,
  paymentMethodId,
  priceId,
  studentData,
  paymentGateway,
  source,
  upgradeReason,
  productId,
  couponId,
  verifyAccountExistence,
  setupIntentId,
  headers
}) => {
  const reqData = {
    email,
    name,
    paymentMethodId,
    priceId,
    studentData,
    paymentGateway,
    source,
    upgradeReason,
    productId,
    couponId,
    verifyAccountExistence,
    setupIntentId
  }
  const route = paymentGateway === 'PADDLE' ? 'create-sub-paddle' : 'create-subscription'
  return api.post(`${API_ROOT}/${route}`, reqData, headers).then((result) => {
    if (result.error) {
      throw result
    }
    return result
  })
}

export const createPaymentMethod = (stripe, cardElement) =>
  stripe.createPaymentMethod({
    type: 'card',
    card: cardElement
  })

export const createOneTimePayment = (stripe, clientSecret, card) =>
  stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: card
    },
    setup_future_usage: 'off_session'
  })

import api from '../utils/api'
import { API_ROOT } from '../constants'
import { trackReferral } from '../services/track-referral'
import { getTrialAnalytics } from '../services/manage'
import { isMoreThanOneDay, isTrialAccount } from '../utils/general'

export const FETCHING_USER = 'FETCHING_USER'
export const fetchingUser = (payload) => ({
  type: FETCHING_USER,
  payload
})

export const INSERT_USER_PROFILE = 'INSERT_USER_PROFILE'
export const insertUserProfile = (data) => ({
  type: INSERT_USER_PROFILE,
  payload: {
    ...data
  }
})

export const ACCOUNT_MODAL = 'ACCOUNT_MODAL'
export const accountModal = (show) => ({
  type: ACCOUNT_MODAL,
  payload: show
})

export const UPGRADE_MODAL = 'UPGRADE_MODAL'
export const showUpgradeModal = (show) => {
  if (show) {
    window.scrollTo(0, 0)
  }

  return {
    type: UPGRADE_MODAL,
    payload: show
  }
}

export const fetchUserData =
  (loading = true) =>
  (dispatch) => {
    dispatch(fetchingUser(loading))
    api
      .post(`${API_ROOT}/pro/manage`)
      .then(async (res) => {
        const data = res.data
        dispatch(fetchingUser(false))
        dispatch(insertUserProfile(data))

        const { userProfile } = data
        if (userProfile?.productId && localStorage.getItem('referral')) {
          trackReferral(userProfile.email)
        }

        // notify user feedback module
        if (userProfile?.productId && isMoreThanOneDay(userProfile.created)) {
          window.InsightPipeline?.update({ show: true })
        }
      })
      .catch((err) => {
        console.error('fetchUserProfile', err)
        localStorage.removeItem('token')
        dispatch(fetchingUser(false))
        window.location = '/login'
      })
  }

export const ACTION_PROCESSING = 'ACTION_PROCESSING'
export const actionProcessing = (payload) => ({
  type: ACTION_PROCESSING,
  payload
})

export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL'
export const showMessageModal = (payload) => ({
  type: SHOW_MESSAGE_MODAL,
  payload
})

export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL'
export const hideMessageModal = () => ({
  type: HIDE_MESSAGE_MODAL
})

export const CUSTOM_DOMAIN_MODAL = 'CUSTOM_DOMAIN_MODAL'
export const showCustomDomainModal = (payload) => ({
  type: CUSTOM_DOMAIN_MODAL,
  payload
})

export const CREATE_SITE_MODAL = 'CREATE_SITE_MODAL'
export const showCreateSiteModal = (visible, data, action) => ({
  type: CREATE_SITE_MODAL,
  payload: {
    visible,
    data,
    action
  }
})

export const DRAGGED_FILE = 'DRAGGED_FILE'
export const setDraggedFile = (file) => {
  return {
    type: DRAGGED_FILE,
    payload: file
  }
}

export const SITE_TYPE = 'SITE_TYPE'
export const setSiteType = (siteType) => {
  return {
    type: SITE_TYPE,
    payload: siteType
  }
}

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const toggleModal = (payload) => {
  return {
    type: TOGGLE_MODAL,
    payload
  }
}

export const TOGGLE_TEAM_MODAL = 'TOGGLE_TEAM_MODAL'
export const toggleTeamModal = (payload) => {
  return {
    type: TOGGLE_TEAM_MODAL,
    payload
  }
}

export const SHOW_QRCODE_MODAL = 'SHOW_QRCODE_MODAL'
export const setQrCodeModal = (payload) => {
  return {
    type: SHOW_QRCODE_MODAL,
    payload
  }
}

export const SHOW_FIRST_TIME_MODAL = 'SHOW_FIRST_TIME_MODAL'
export const setFirstTimeSuccModal = (payload) => {
  return {
    type: SHOW_FIRST_TIME_MODAL,
    payload
  }
}

export const SET_QRCODE_DATA = 'SET_QRCODE_DATA'
export const setQrCodeData = (payload) => {
  return {
    type: SET_QRCODE_DATA,
    payload
  }
}

export const SET_EMBED_DATA = 'SET_EMBED_DATA'
export const setEmbedData = (show, site) => {
  return {
    type: SET_EMBED_DATA,
    payload: {
      show: show,
      site: site
    }
  }
}

export const SET_API_KEY = 'SET_API_KEY'
export const setAPIKey = (payload) => {
  return {
    type: SET_API_KEY,
    payload
  }
}

export const SET_ACTION = 'SET_ACTION'
export const setAction = (payload) => {
  return {
    type: SET_ACTION,
    payload
  }
}

export const SHOW_PREVIEW_MODAL = 'SHOW_PREVIEW_MODAL'
export const setPreviewModal = (payload) => {
  return {
    type: SHOW_PREVIEW_MODAL,
    payload
  }
}

export const SET_PREVIEW_DATA = 'SET_PREVIEW_DATA'
export const setPreviewData = (payload) => {
  return {
    type: SET_PREVIEW_DATA,
    payload
  }
}

export const TOGGLE_SIGNUP_MODAL = 'TOGGLE_SIGNUP_MODAL'
export const toggleSignUpModal = (isVisible) => ({
  type: TOGGLE_SIGNUP_MODAL,
  payload: isVisible
})

export const SET_LOADING_SITES_STORE = 'SET_LOADING_SITES_STORE'
export const setLoadingSitesStore = (payload) => ({
  type: SET_LOADING_SITES_STORE,
  payload
})

export const RESET_USER_PROFILE = 'RESET_USER_PROFILE'
export const resetUserProfile = () => ({
  type: RESET_USER_PROFILE
})

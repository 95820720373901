import { HIDE_UPGRADE_CARD_MODAL, SHOW_UPGRADE_CARD_MODAL, SHOW_PAYMENT_MODAL } from '../actions'

const initState = {
  source: '',
  show: false,
  showPaymentModal: false,
  upgradePlanId: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case HIDE_UPGRADE_CARD_MODAL:
      return {
        show: false
      }
    case SHOW_UPGRADE_CARD_MODAL:
      return {
        ...state,
        ...action.payload,
        show: true
      }
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.payload.showPaymentModal,
        upgradePlanId: action.payload.upgradePlanId
      }
    default:
      return state
  }
}

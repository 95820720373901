import { isDevOrPreviewSite } from '../../../utils/general'

const PRODUCT_ID_PROD = 'prod_J8jz9uooW4PLna'
const PRODUCT_ID_DEV = 'prod_J8kEW4NCKrP3nK'

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD

const PRICE_ID_MONTHLY_PROD = 'price_1J6iKiKnX1nnNv6IJqAigskY'
const PRICE_ID_MONTHLY_DEV = 'price_1J6iv1KnX1nnNv6IFudmimSa'
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD

const PADDLE_PRICE_ID_YEARLY_PROD = 661357
const PADDLE_PRICE_ID_YEARLY_DEV = 20457

const PADDLE_PRICE_ID_MONTHLY_PROD = 661359
const PADDLE_PRICE_ID_MONTHLY_DEV = 20458

const PRICE_ID_YEARLY_PROD = 'price_1J6iORKnX1nnNv6IqI9ZGe2k'
const PRICE_ID_YEARLY_DEV = 'price_1J6ivMKnX1nnNv6IU0JGRyf0'
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD

const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD

const PRO_U_PLAN = {
  id: PRODUCT_ID,
  label: 'Pro',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 5400,
      ccy: 'Rs.',
      paddleLink: 'https://buy.paddle.com/product/661357',
      paddleId: PADDLE_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 600,
      ccy: 'Rs.',
      paddleLink: 'https://buy.paddle.com/product/661359',
      paddleId: PADDLE_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 150,
  customDomainsLimit: 5
}

export default PRO_U_PLAN

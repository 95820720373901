import React, { useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { mbValue } from '../../../utils/general'
import './LoadingBox.css'
import { ACTION_CREATE, ACTION_UPDATE } from '../interface'

let progressInterval = null

export const getSimulationDuration = (fileSize) => {
  if (fileSize < mbValue(100)) {
    return 300
  } else if (fileSize >= mbValue(100) && fileSize < mbValue(500)) {
    return 1000
  } else if (fileSize >= mbValue(500)) {
    return 1500
  }
}

export const SimulatedProgressBar = ({ start, duration = 300, progressBarMessage, action }) => {
  const [progress, setProgress] = useState(start)
  const message = action === ACTION_CREATE ? 'Creating site, please wait...' : 'Updating site, please wait...'

  useEffect(() => {
    progressInterval = setInterval(() => {
      setProgress((prev) => prev + 1)
    }, duration)
  }, [])

  useEffect(() => {
    if (progress >= 99) {
      clearInterval(progressInterval)
    }
  }, [progress])

  return <ProgressBarLoader now={progress} progressBarMessage={progress >= 99 ? message : progressBarMessage} />
}

const ProgressBarLoader = ({ className = '', progressBarMessage = 'Working magic...', now }) => {
  return (
    <div className={`progress-bar-loader ${className}`}>
      <div className="mb-3">{progressBarMessage}</div>
      <ProgressBar animated now={now} label={`${now}%`} />
    </div>
  )
}

export const LoadingBox = ({
  className = '',
  showProgressBar,
  showSimulationProgressBar,
  progress,
  simulatedDurationProgress,
  message = 'Working magic...',
  action
}) => {
  return (
    <div className={`loading-box ${className}`}>
      {showSimulationProgressBar && (
        <SimulatedProgressBar
          start={50}
          duration={simulatedDurationProgress}
          progressBarMessage={message}
          action={action}
        />
      )}

      {showProgressBar && <ProgressBarLoader now={progress} progressBarMessage={message} />}

      {!showProgressBar && !showSimulationProgressBar && (
        <>
          <Spinner animation="border" />
          <div className="mt-3">{message}</div>
        </>
      )}
    </div>
  )
}

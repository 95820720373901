import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import styles from './FeatureGrid.module.css'

const items = [
  { icon: 'domain.svg', text: 'Connect custom domain' },
  { icon: 'code-block.svg', text: 'Paste HTML' },
  { icon: 'add-user.svg', text: 'Invite team members' },
  { icon: 'integration.svg', text: 'Integrations' },
  { icon: 'globe.svg', text: 'Content Delivery Network (CDN)' },
  { icon: 'lock-rect.svg', text: 'SSL Included' }
]

const FeatureGrid = () => {
  return (
    <Container className="mt-5">
      <div className="header grey font-20">And much more...</div>
      <Row className="g-4">
        {items.map((item, index) => (
          <Col xs={12} md={4} key={index}>
            <div className={`h-100 d-flex flex-column justify-content-center align-items-center ${styles.card}`}>
              <div style={{ fontSize: '75px' }}>
                <img src={`/assets/icons/${item.icon}`} height={50} className="opacity-75" />
              </div>
              <Card.Body>
                <Card.Title className="text-center bold font-18">{item.text}</Card.Title>
              </Card.Body>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default FeatureGrid

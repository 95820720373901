import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'

const GoogleApplePay = ({ priceData, productId, processTransaction }) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  useEffect(() => {
    setPaymentRequest(null)
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Tiiny Host Subscription',
          amount: priceData.value * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      pr.on('paymentmethod', async (res) => {
        const { paymentMethod, payerName: name, payerEmail: email } = res

        if (paymentMethod) {
          processTransaction(
            {
              name,
              email,
              priceId: priceData.id,
              paymentGateway: 'STRIPE',
              productId
            },
            paymentMethod.id
          )
          res.complete('success')
        } else {
          res.complete('fail')
        }
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe, priceData])

  return paymentRequest ? (
    <Row className="mb-3 tr-apple-google-pay">
      <Col>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </Col>
    </Row>
  ) : null
}

export default GoogleApplePay

import React, { useState } from 'react'

import { connect } from 'react-redux'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'

import { PLANS } from '../../constants/plans'
import StudentVerification from '../StudentVerification'
import { QUARTERLY } from '../../constants/plans/pro-student'
import PricingGrid from '../PricingGrid/PricingGrid'
import CurrencyDropdown from 'components/CurrencyDropdown/CurrencyDropDown'
import PlanPaymentModal from 'components/PlanPaymentModal/PlanPaymentModal'
import { setShowPaymentModal } from 'actions'

import './ProPlans.css'

import { getCurrencyAndPrice, getPlansPricesFromCurrency, availableCurrencies } from 'constants/pricing/currency'

export const ProPlans = ({
  className,
  onFreeClick,
  email,
  hideFreeCard,
  hideTable,
  onPaymentClickCallback,
  onPaymentHideCallback,
  source,
  dispatch,
  showPaymentModal,
  upgradePlanId
}) => {
  const [pricingMode, setPricingMode] = useState('monthly')

  const [showStudentPlans, setShowStudentPlans] = useState(false)
  const [showStudentVerification, setShowStudentVerification] = useState(false)
  const [studentData, setStudentData] = useState()

  const [prices, setPrices] = useState(getCurrencyAndPrice())
  const token = localStorage.getItem('token')

  const onSubPlanClick = (plan) => {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: plan }))
    if (onPaymentClickCallback) {
      onPaymentClickCallback()
    }
  }

  const onStudentContinue = (email, school) => {
    setStudentData({ email, school })
    setShowStudentVerification(false)
    onSubPlanClick(PLANS.PRO_STUDENT.id)
  }

  const onPaymentModalHide = () => {
    dispatch(setShowPaymentModal({ showPaymentModal: false, upgradePlanId: null }))
    localStorage.removeItem('discount-code')
    if (onPaymentHideCallback) onPaymentHideCallback()
  }

  const onCurrencySelected = (currency) => {
    setPrices(getPlansPricesFromCurrency(currency))
  }

  return (
    <>
      {/* used for not logged in user */}
      {!token && (
        <PlanPaymentModal
          show={showPaymentModal}
          onHide={onPaymentModalHide}
          planId={upgradePlanId}
          history={history}
          studentData={studentData}
          periodProp={showStudentPlans ? QUARTERLY : pricingMode}
          source={source}
          email={email}
        />
      )}
      <StudentVerification
        show={showStudentVerification}
        onHide={() => setShowStudentVerification(false)}
        onContinue={onStudentContinue}
      />
      <div id="pricing" className={`mt-3 mb-3 position-relative ${className}`}>
        <ButtonGroup>
          <ToggleButton
            id="monthly"
            className="cursor-pointer"
            size="sm"
            value="monthly"
            variant="outline-dark"
            type="radio"
            name="radio"
            checked={pricingMode === 'monthly'}
            onChange={(e) => setPricingMode(e.currentTarget.value)}
          >
            Monthly
          </ToggleButton>
          <ToggleButton
            id="yearly"
            className="cursor-pointer"
            type="radio"
            name="radio"
            size="sm"
            variant="outline-dark"
            value="yearly"
            checked={pricingMode === 'yearly'}
            onChange={(e) => setPricingMode(e.currentTarget.value)}
          >
            Yearly
          </ToggleButton>
        </ButtonGroup>

        <div className="mt-4 mb-5 text-sm">
          Questions?{' '}
          <span className="link color-purple" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            <u>Chat with us</u>
          </span>
          <div className="guarantee mt-3 font-16 grey bg-light-grey m-auto p-2">
            🎖️ 7 day money back guarantee
          </div>
        </div>
        {localStorage.getItem('discount-code') && (
          <div style={{ marginBottom: '48px' }}>
            <span className="discount">Discount applied during checkout!</span>
          </div>
        )}
        <CurrencyDropdown
          selectedCurrency={prices.freePlan.monthly.currency}
          onCurrencySelected={onCurrencySelected}
          availableCurrencies={availableCurrencies}
        />
        <div id="pricing" className="container-cards">
          <PricingGrid
            className="mt-4"
            period={pricingMode}
            prices={prices}
            onSubPlanClick={onSubPlanClick}
            onFreeClick={onFreeClick}
            hideFreeCard={hideFreeCard}
            hideTable={hideTable}
          />
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = ({ upgrade, manage }) => ({
  showPaymentModal: upgrade.showPaymentModal,
  upgradePlanId: upgrade.upgradePlanId,
  userProfile: manage.userProfile
})

export default connect(mapStateToProps, mapDispatchToProps)(ProPlans)

import React from 'react'
import { connect } from 'react-redux'
import { showUpgradeModal as showSubscribeModal } from '../../Manage/actions'
import styles from './UpgradeBadge.module.css'

const UpgradeBadge = ({ dispatch, show = false, onUpgrade }) => {
  return (
    <>
      {show && (
        <img className={styles.badge} alt="Pro" src="/assets/icons/pro-black.png" height="20" onClick={onUpgrade} />
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(undefined, mapDispatchToProps)(UpgradeBadge)

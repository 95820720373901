import React from 'react'
import styles from './DomainCell.module.css'

const DomainCell = ({ label, href, site, handlePreviewClick, disabled }) => {
  const onDomainClick = (e) => {
    e.preventDefault()
    if (site?.linkPreview) {
      handlePreviewClick(site)
    } else {
      window.open(href, '_blank')
    }
  }
  const updatedLabel = !site?.linkPreview ? label : 'Click to preview'

  return (
    <div className={styles.container}>
      <a
        href={href}
        onClick={onDomainClick}
        className={`${styles.link} ${disabled ? styles.disabled : ''}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={styles.text}>{updatedLabel}</span>
      </a>
    </div>
  )
}

export default DomainCell

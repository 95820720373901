import { isDevOrPreviewSite } from '../../utils/general'

export const TINY_PLAN_PRICES = {
  yearly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVb5WKnX1nnNv6IWtPB3gru' : 'price_1PlVGeKnX1nnNv6IEpYw5K6m',
      currency: '€',
      value: 60
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVb8yKnX1nnNv6InXSJFugh' : 'price_1PlUzaKnX1nnNv6IiQgnCyor',
      currency: '£',
      value: 48
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1Nn2r6KnX1nnNv6IQK8YG4sv' : 'price_1Nn2rtKnX1nnNv6IgIsA0Wxe',
      currency: '$',
      value: 60
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVbCCKnX1nnNv6I4QQHWHSL' : 'price_1Q7FFSKnX1nnNv6IaQwkqWDQ',
      currency: '₹',
      value: 2520
    }
  },
  monthly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVb3cKnX1nnNv6I4pogjax4' : 'price_1PlVHIKnX1nnNv6I958myuP7',
      currency: '€',
      value: 9
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVazKKnX1nnNv6IMOXCHbtl' : 'price_1PlUymKnX1nnNv6Io90j5mbk',
      currency: '£',
      value: 7
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1Nn2nnKnX1nnNv6IzoEC0ngV' : 'price_1Nn2rtKnX1nnNv6Isc95ltdV',
      currency: '$',
      value: 9
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVbFlKnX1nnNv6IZrXsjP2h' : 'price_1Q7FHlKnX1nnNv6IQSwg0smE',
      currency: '₹',
      value: 325
    }
  }
}

import React from 'react'
import styles from './FAQ.module.css'
import Accordion from 'react-bootstrap/Accordion'
import FAQS from './FAQ.string'
import { Button } from 'react-bootstrap'

const FAQ = () => (
  <div className={styles.container}>
    <div className={styles.faqList}>
      <h2 className={styles.h2}>Frequently Asked Questions</h2>
      <Accordion className={styles.accordion} flush>
        {FAQS.map(({ q, a }, i) => (
          <Accordion.Item key={i} eventKey={i}>
            <Accordion.Header className="font-18">{q}</Accordion.Header>
            <Accordion.Body className="text-start font-18">
              <span dangerouslySetInnerHTML={{ __html: a }} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
    <div className={styles.ctaBox}>
      <h3 className={styles.h3}>Publish to the web in seconds</h3>
      <div className="m-auto">
        <Button href="/#home" className={styles.ctaButton}>
          Upload file &rarr;
        </Button>
      </div>
    </div>
  </div>
)

export default FAQ

import { getFullDomainValue } from 'utils/general'
import { REGEX_DOMAIN, REGEX_ERROR_PATH, REGEX_PASSWORD } from '../../constants'
import { ACTION_CREATE, GATED_MODE_EMAIL_GATE, GATED_MODE_PASSWORD } from './interface'
import { files } from 'jszip'
import { addIsIndexToFileObject } from 'utils/file'

export const validate = ({ domainValues, uploadValues, toggleValues }) => {
  const errors = {}

  for (let fieldName in domainValues) {
    const value = domainValues[fieldName]
    if ((fieldName === 'subdomain' && value) || (domainValues.isCustomDomain && fieldName === 'subdomain' && !value)) {
      // subdomains for custom domain can't be empty
      if (!REGEX_DOMAIN.test(value?.toLowerCase())) {
        errors.domainValues = { ...errors.domainValues, subdomain: 'Please enter a valid link-name.' }
      }
    }
  }

  for (let uploadKey in uploadValues) {
    if (uploadKey === 'selectedFile' && !uploadValues[uploadKey]) {
      errors.uploadValues = { ...errors.uploadValues, selectedFile: 'Please select a file' }
    }
  }

  for (let toggleKey in toggleValues) {
    if (toggleKey === 'password' && toggleValues['passwordProtected']) {
      if (!REGEX_PASSWORD.test(toggleValues[toggleKey]?.toLowerCase())) {
        errors.toggleValues = { ...errors.toggleValues, password: 'Please enter a valid password' }
      }
    }

    if (toggleKey === 'errorPath' && toggleValues['siteConfiguration']) {
      if (!REGEX_ERROR_PATH.test(toggleValues[toggleKey]?.toLowerCase())) {
        errors.toggleValues = {
          ...errors.toggleValues,
          errorPath: 'Please enter a valid error path. Path should always begin with /. Eg: /index.html'
        }
      }
    }
  }

  return errors
}

/** Care about changing this one, it get only form values changed by the user and create valid fields for request */
export const getUpdatedFields = (values, initialValues) => {
  let newValues = values
  let allCurrentFiles
  let allUpdatedFiles
  let filesToRemove
  let filesToAdd

  const updatedFields = Object.keys(newValues).reduce((acc, key) => {
    if (values[key] !== initialValues[key]) {
      if (key === 'subdomain' || key === 'domainSuffix') {
        acc['subdomain'] = values['subdomain']
        acc['domainSuffix'] = values['domainSuffix']
      } else if (key === 'disableExportPdf' || key === 'displayMode') {
        acc['disableExportPdf'] = values['disableExportPdf']
        acc['displayMode'] = values['displayMode']
      } else if (key === 'selectedFile' || key === 'addedFiles') {
        allCurrentFiles = initialValues.selectedFile ? [initialValues.selectedFile, ...initialValues.addedFiles] : []
        allUpdatedFiles = values.selectedFile ? [values.selectedFile, ...values.addedFiles] : []

        filesToRemove = allCurrentFiles.filter((currentFile) => {
          if (allUpdatedFiles.map((file) => file.name).includes(currentFile.name)) {
            if (allUpdatedFiles.find((file) => file.name === currentFile.name) instanceof File) return true
            return false
          } else return true
        })

        filesToAdd = allUpdatedFiles.filter((updatedFile) => {
          if (allCurrentFiles.map((file) => file.name).includes(updatedFile.name)) {
            if (updatedFile instanceof File) return true
            return false
          } else return true
        })

        acc['filesToAdd'] = filesToAdd
        acc['filesToRemove'] = filesToRemove
      } else {
        acc[key] = values[key]
      }
    }
    if (key === 'siteType') {
      acc['siteType'] = values['siteType']
    }

    return acc
  }, {})

  return Object.keys(updatedFields).length === 0 ? null : updatedFields
}

export const updateSiteRequest = ({ domainValuesToUpdate, toggleValuesToUpdate, uploadValuesToUpdate, siteType }) => {
  let newDomain = getFullDomainValue(domainValuesToUpdate?.subdomain, domainValuesToUpdate?.domainSuffix) || null
  let siteSettings = toggleValuesToUpdate
  let filesToAdd = uploadValuesToUpdate?.filesToAdd
  let filesToRemove = uploadValuesToUpdate?.filesToRemove
  let indexFile = uploadValuesToUpdate?.indexFile

  if (siteSettings?.disableIndexing) {
    siteSettings.disableIndexing = siteSettings.disableIndexing.toString()
  }

  if (siteSettings?.siteConfiguration || siteSettings?.errorPath) {
    siteSettings.siteConfiguration = JSON.stringify({ errorPath: siteSettings.errorPath })
    delete siteSettings.errorPath
  }

  if (siteSettings?.gatedMode) {
    switch (siteSettings.gatedMode) {
      case GATED_MODE_EMAIL_GATE:
        siteSettings.enableEmailGate = true
        siteSettings.passwordProtected = false
        break
      case GATED_MODE_PASSWORD:
        siteSettings.enableEmailGate = false
        siteSettings.passwordProtected = true
        break
      default:
        siteSettings.enableEmailGate = false
        siteSettings.passwordProtected = false
    }
    delete siteSettings?.gatedMode
  }

  if (siteType) {
    return {
      newDomain,
      siteSettings,
      filesToAdd,
      filesToRemove,
      indexFile,
      siteType
    }
  }

  return {
    newDomain,
    siteSettings,
    filesToAdd,
    filesToRemove,
    indexFile
  }
}

export const createNewSiteRequest = ({ domainValues, toggleValues, uploadValues, siteType }) => {
  let newDomain = domainValues?.subdomain
    ? getFullDomainValue(domainValues?.subdomain, domainValues?.domainSuffix)
    : null
  let domainSuffix = domainValues.domainSuffix
  let siteSettings = { ...toggleValues }
  let filesToAdd = uploadValues?.filesToAdd
  let indexFile = uploadValues?.indexFile
  let isZipPhpFile = uploadValues?.isZipPhpFile

  if (siteSettings?.disableIndexing) {
    siteSettings.disableIndexing = siteSettings.disableIndexing.toString()
  }

  if (siteSettings?.siteConfiguration && siteSettings?.errorPath) {
    siteSettings.siteConfiguration = JSON.stringify({ errorPath: siteSettings.errorPath })
    delete siteSettings.errorPath
  }

  if (siteSettings?.gatedMode) {
    switch (siteSettings.gatedMode) {
      case GATED_MODE_EMAIL_GATE:
        siteSettings.enableEmailGate = true
        siteSettings.passwordProtected = false
        break
      case GATED_MODE_PASSWORD:
        siteSettings.enableEmailGate = false
        siteSettings.passwordProtected = true
        break
      default:
        siteSettings.enableEmailGate = false
        siteSettings.passwordProtected = false
    }
    delete siteSettings?.gatedMode
  }

  if (siteType) {
    return {
      newDomain,
      domainSuffix,
      siteSettings,
      filesToAdd,
      indexFile,
      siteType
    }
  }

  return {
    newDomain,
    domainSuffix,
    siteSettings,
    filesToAdd,
    indexFile,
    isZipPhpFile
  }
}

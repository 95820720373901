import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { Alert, Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import HomeHeader from '../components/HomePageHeader'
import Footer from '../components/Footer'
import { reCAPTCHA_CLIENT, REGEX_FULLDOMAIN } from '../constants'
import { reportSite } from '../services/report-site'
import { errors } from '../constants'

const schema = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  notes: Yup.string().required('Issue notes are required'),
  site: Yup.string().matches(REGEX_FULLDOMAIN, 'Please enter a valid site').required('Site is required')
})

const STAGE_INITIAL = 0
const STAGE_SUCCESS = 1
const STAGE_ERROR = 2
const STAGE_INVALID_URL = 3
const STAGE_INVALID_REQUEST = 4

const ReportSite = ({ googleReCaptchaProps }) => {
  const [stage, setStage] = useState(STAGE_INITIAL)
  const [isReported, setIsReported] = useState(false)

  const onSubmit = async ({ email, notes, site }) => {
    setIsReported(true)

    const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('report_site')
    const headers = { 'x-recaptcha': recaptchaValue }

    reportSite({ site, email, notes }, headers)
      .then((res) => {
        if (res.data.success) {
          setStage(STAGE_SUCCESS)
        } else {
          setStage(STAGE_ERROR)
        }
      })
      .catch((error) => {
        if (!error?.response?.data?.success) {
          setStage(error?.response?.data.code === errors.INVALID_DOMAIN ? STAGE_INVALID_URL : STAGE_INVALID_REQUEST)
        } else {
          setStage(STAGE_ERROR)
        }
      })
      .finally(() => setIsReported(false))
  }

  return (
    <div className="full-height">
      <HomeHeader />
      <div className="container">
        <h1 className="mt-5 bold">Report Site</h1>
        <p>Please provide the details of the site being reported</p>
        <div className="box-100 mt-4 mb-5 p-3">
          {stage === STAGE_INVALID_URL && !isReported && (
            <Alert variant="danger">
              <div>Sorry, we do not host this url</div>
            </Alert>
          )}
          {stage === STAGE_INVALID_REQUEST && !isReported && (
            <Alert variant="danger">
              <div>Invalid Request, Please provide valid details</div>
            </Alert>
          )}
          {stage === STAGE_ERROR && !isReported && (
            <Alert variant="danger">
              <div>Oops, something went wrong. Please try again.</div>
            </Alert>
          )}
          {stage === STAGE_SUCCESS && (
            <div>
              <p>Thank you, your report was submitted successfully.</p>
              <Button onClick={() => setStage(STAGE_INITIAL)}>Done</Button>
            </div>
          )}
          {stage !== STAGE_SUCCESS && (
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ email: '', site: '', notes: '' }}>
              {({ handleSubmit, handleChange, values }) => (
                <form onSubmit={handleSubmit}>
                  <InputGroup className="mt-2">
                    <InputGroup.Text id="basic-addon3">https://</InputGroup.Text>
                    <Form.Control
                      name="site"
                      type="text"
                      placeholder="url"
                      value={values.site}
                      onChange={handleChange}
                      disabled={isReported}
                    />
                  </InputGroup>
                  <ErrorMessage name="site" component="div" className="validation-error" />
                  <Form.Control
                    className="mt-2"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={isReported}
                  />
                  <ErrorMessage name="email" component="div" className="validation-error" />
                  <Form.Control
                    className="mt-3"
                    name="notes"
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Whats the issue?"
                    value={values.notes}
                    onChange={handleChange}
                    disabled={isReported}
                  />
                  <ErrorMessage name="notes" component="div" className="validation-error" />

                  <Button className="me-auto mt-3 float-right" type="submit" disabled={isReported}>
                    {isReported ? <Spinner animation="border" variant="light" size="sm" /> : 'Submit'}
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

const ReportSiteWithReCaptcha = withGoogleReCaptcha(ReportSite)

const ReCaptchaWrapper = () => (
  <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA_CLIENT}>
    <ReportSiteWithReCaptcha />
  </GoogleReCaptchaProvider>
)

export default ReCaptchaWrapper

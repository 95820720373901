import { isDevOrPreviewSite } from '../../utils/general'

export const SOLO_PLAN_PRICES = {
  yearly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcXOKnX1nnNv6I7wLpEKD6' : 'price_1PVcupKnX1nnNv6InHV1dGMn',
      currency: '€',
      value: 156
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVccTKnX1nnNv6Iz5pLqDm2' : 'price_1PVcwSKnX1nnNv6IYbsyX6Il',
      currency: '£',
      value: 120
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1KfVJZKnX1nnNv6IkJoWrMxg' : 'price_1KfV3kKnX1nnNv6IwPx129d6',
      currency: '$',
      value: 156
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcdKKnX1nnNv6I60V800oi' : 'price_1Q7FJaKnX1nnNv6IeLyQRISf',
      currency: '₹',
      value: 6600
    }
  },
  monthly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcf2KnX1nnNv6IOrWN6ltG' : 'price_1PlVNlKnX1nnNv6IbmYRSOPC',
      currency: '€',
      value: 18
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVce4KnX1nnNv6II4M7tgTN' : 'price_1PlVOzKnX1nnNv6IzmBdZEi9',
      currency: '£',
      value: 14
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1KfVLOKnX1nnNv6IRf8vpcvK' : 'price_1KfV2zKnX1nnNv6ICHQPi5Qp',
      currency: '$',
      value: 18
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcfhKnX1nnNv6IGElnCqKd' : 'price_1Q7FKUKnX1nnNv6ImYL3tGSI',
      currency: '₹',
      value: 750
    }
  }
}

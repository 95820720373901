import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { availableCurrenciesSubset, getCurrencyNameBySymbol } from '../../constants/pricing/currency'

const CurrencyDropdown = ({ selectedCurrency, onCurrencySelected }) => {
  if (selectedCurrency === '₹')
    // TODO: IND-EXP
    return null

  return (
    <DropdownButton
      className="text-start"
      title={`${selectedCurrency} (${getCurrencyNameBySymbol(selectedCurrency)})`}
      size="sm"
      variant="outline-secondary"
    >
      {availableCurrenciesSubset.map((currency) => (
        <Dropdown.Item
          key={currency.symbol}
          eventKey={currency.symbol}
          onClick={() => onCurrencySelected(currency.symbol)}
        >
          {currency.symbol} ({currency.name})
        </Dropdown.Item>
      ))}
    </DropdownButton>
  )
}

export default CurrencyDropdown

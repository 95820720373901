import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const MessageModal = ({ show, handleNeutral, message, title, error }) => (
  <Modal aria-labelledby="contained-modal-title-vcenter" show={show} animation={false} centered>
    <div className="ps-3 pe-3">
      <div className="text-center mb-4 mt-4">
        <h4 className="mt-3 mb-4">{title}</h4>
        <p className="grey w-75 m-auto">{message}</p>
        {error && (
          <p className="mt-3 link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            <u>Contact support</u>
          </p>
        )}
      </div>
      <Button className="action-btn mb-3 w-100" onClick={handleNeutral}>
        OK
      </Button>
    </div>
  </Modal>
)

export default MessageModal

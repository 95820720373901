import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Details from './components/Details'
import Cancel from './../CancelAccount/Cancel'
import BorderButton from '../BorderButton/index.b'
import { showUpgradeModal } from '../../Manage/actions'

import './ManageAcccountModal.css'

const STAGE_DETAILS = 'DETAILS'
const STAGE_CANCEL = 'CANCEL'

const ManageAccountModal = ({
  customerId = '',
  show,
  onHide,
  cardType,
  productId,
  lastFour,
  status,
  amount,
  interval,
  nextBilling,
  apiKey,
  email,
  currency = '$',
  cancelUrl,
  updateUrl,
  onUpgrade,
  dispatch,
  liveSites,
  pageViews
}) => {
  const [stage, setStage] = useState(STAGE_DETAILS)
  const stripeCustomer = `${customerId}`.indexOf('cus_') !== -1
  const trialAccount = !productId

  const onCancel = () => {
    setStage(STAGE_CANCEL)
  }

  const handleOnHide = () => {
    setStage(STAGE_DETAILS)
    onHide()
  }

  return (
    <Modal show={show} onHide={handleOnHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="p-4">
        {stage === STAGE_DETAILS && (
          <>
            <Details
              nextBilling={nextBilling}
              amount={amount}
              interval={interval}
              cardType={cardType}
              productId={productId}
              lastFour={lastFour}
              status={status}
              apiKey={apiKey}
              email={email}
              currency={currency}
              stripeCustomer={stripeCustomer}
              cancelUrl={cancelUrl}
              updateUrl={updateUrl}
              onCancel={onCancel}
              onUpgrade={onUpgrade}
            />
            {trialAccount && (
              <BorderButton
                className="tr-header-upgrade-btn float-right"
                onClick={() => dispatch(showUpgradeModal(true))}
                label="Upgrade for more"
              />
            )}
          </>
        )}
        {stage === STAGE_CANCEL && (
          <Cancel
            sites={liveSites}
            stripeCustomer={stripeCustomer}
            onHide={handleOnHide}
            visitorCount={pageViews}
            currentPlanId={productId}
            onUpgrade={onUpgrade}
          />
        )}
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ manage }) => ({
  ...manage.userProfile,
  liveSites: manage.liveSites
})
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccountModal)

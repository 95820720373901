import React from 'react'
import styles from './CustomTableHeader.module.css'
import { Button } from 'react-bootstrap'

const CustomTableHeader = ({ title, badge, buttons }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftSection}>
        <h2 className={styles.title}>{title}</h2>
        {badge && <span className={styles.badge}>{badge}</span>}
      </div>
      <div className={styles.rightSection}>
        {buttons.map((button, index) => {
          if (button.type === 'custom_button') {
            return <>{button.component}</>
          }
          return (
            <Button key={index} onClick={button.onClick} className={`${styles.button} ${button.className || ''}`}>
              {button.icon && <img src={button.icon} alt="" className={styles.buttonIcon} />}
              {button.text}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default CustomTableHeader

import React, { useState } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { withRouter } from 'react-router'
import * as Yup from 'yup'
import { ErrorMessage, Formik } from 'formik'
import { useStripe } from '@stripe/react-stripe-js'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import api from 'utils/api'
import { API_ROOT } from 'constants'
import { LoadingBox } from 'components/LoadingBox/LoadingBox'
import { ErrorBox } from 'components/ErrorBox/ErrorBox'
import * as Sentry from '@sentry/react'

const schema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Email is invalid').required('Email is required')
})

const PaymentModal = ({ className, priceData, email, productId, studentData = {}, googleReCaptchaProps }) => {
  const stripe = useStripe()
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)
  const { id: priceId } = priceData

  const urlParams = new URLSearchParams(window.location.search)
  const mode = urlParams.get('mode')
  const paypalTest = mode === 'paypal'

  const onSubmit = async (formData) => {
    try {
      setLoading(true)

      const recaptchaValue = await googleReCaptchaProps.executeRecaptcha('payment')
      const headers = { 'x-recaptcha': recaptchaValue }

      // Create the PaymentIntent
      const {
        data: { clientSecret }
      } = await api.post(
        `${API_ROOT}/create-payment-intent`,
        {
          ...formData,
          priceId: paypalTest ? 'price_1NXn5QKnX1nnNv6IEJcVnAEy' : priceId,
          productId,
          action: 'setup-payment-intent',
          paymentMethod: 'paypal'
        },
        headers
      )

      const { error } = await stripe.confirmPayPalSetup(clientSecret, {
        return_url: `${API_ROOT}/stripe/paypal/callback`,
        mandate_data: {
          customer_acceptance: {
            type: 'online',
            online: { infer_from_client: true }
          }
        }
      })

      if (error) {
        Sentry.captureException(error, {
          tags: {
            section: 'checkout'
          }
        })
        setLoading(false)
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      } else {
        setLoading(false)
        // The payment UI automatically closes with a success animation.
        // Your customer is redirected to your `return_url`.
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: 'checkout'
        }
      })
      setLoading(false)
      setError(true)
    }
  }

  return (
    <div>
      {!isLoading && !hasError && (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            email: email || studentData.email
          }}
        >
          {({ handleSubmit, handleChange, values }) => (
            <form className={className} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="firstName" component="div" className="validation-error" />
                </Col>
                <Col>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="lastName" component="div" className="validation-error" />
                </Col>
              </Row>
              {!email && (
                <Form.Control
                  className="mt-2"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                />
              )}
              <ErrorMessage name="email" component="div" className="validation-error" />

              <Form.Control
                name="upgradeReason"
                className="mt-2"
                as="select"
                value={values.upgradeReason}
                onChange={handleChange}
              >
                <option value="">Reason for upgrade...</option>
                <option value="custom-domain">Link a custom domain</option>
                <option value="more-links">Upload more projects</option>
                <option value="file-size">Upload a larger file</option>
                <option value="analytics">Analytics</option>
                <option value="editing">Editing functionalities</option>
                <option value="no-ads">Remove Tiiny Host branding</option>
                <option value="other">Other</option>
              </Form.Control>
              <div className="flex">
                <div style={{ opacity: 0.4, fontSize: '14px', flex: 1 }}>
                  Secured with{' '}
                  <a href="https://stripe.com" target="_blank" rel="noreferrer">
                    <img src="/assets/icons/stripe.svg" height="30" />
                  </a>
                </div>
                <div>
                  <Button className="ms-auto me-auto mt-3 float-right tr-stripe-pay" type="submit" disabled={!stripe}>
                    Pay
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      <LoadingBox className={isLoading ? 'd-block' : 'd-none'} message="Processing" />

      {hasError && <ErrorBox hideButton="true" />}
    </div>
  )
}

export default withRouter(withGoogleReCaptcha(PaymentModal))

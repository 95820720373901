import { isDevOrPreviewSite } from '../../../utils/general'

const PRODUCT_ID_PROD = 'prod_HXjN9aML8fXChn'
const PRODUCT_ID_DEV = 'prod_HXjPwsEONzD5oI'

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD

// India test
const PRICE_ID_MONTHLY_PROD = 'price_1J6j0PKnX1nnNv6ImFMC0D64'
const PRICE_ID_MONTHLY_DEV = 'price_1J6j1lKnX1nnNv6IhwVAvIMJ'
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD

const PADDLE_PRICE_ID_YEARLY_PROD = 661356
const PADDLE_PRICE_ID_YEARLY_DEV = 20456

const PADDLE_PRICE_ID_MONTHLY_PROD = 661355
const PADDLE_PRICE_ID_MONTHLY_DEV = 20455

const PRICE_ID_YEARLY_PROD = 'price_1J6j0iKnX1nnNv6I4uVFFLN6'
const PRICE_ID_YEARLY_DEV = 'price_1J6j2JKnX1nnNv6ILenpO8gQ'
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD

const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD

const PRO_PLAN = {
  id: PRODUCT_ID,
  label: 'Pro',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 3600,
      ccy: 'Rs.',
      paddleLink: 'https://buy.paddle.com/product/661356',
      paddleId: PADDLE_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 400,
      ccy: 'Rs.',
      paddleLink: 'https://buy.paddle.com/product/661355',
      paddleId: PADDLE_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 50,
  customDomainsLimit: 5
}

export default PRO_PLAN

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import manageReducer from './reducers/manageReducer'
import pricingReducer from './reducers/pricingReducer'
import upgradeReducer from './reducers/upgradeReducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    manage: manageReducer,
    pricing: pricingReducer,
    upgrade: upgradeReducer
  })

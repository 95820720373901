import React from 'react'

function Placeholder({ isDragReject }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <div>{/* Add your icons here */}</div>
      <div
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        {isDragReject && (
          <p style={{ color: '#fff', fontWeight: 'bolder', wordSpacing: '3px', fontSize: '35px' }}>File not accepted</p>
        )}
        {!isDragReject && (
          <p style={{ color: '#fff', fontWeight: 'bolder', wordSpacing: '3px', fontSize: '35px' }}>Drop file here</p>
        )}
      </div>
    </div>
  )
}

export default Placeholder

import React, { useState } from 'react'
import HomeHeader from '../components/HomePageHeader'
import Footer from '../components/Footer'

import examples from './../Landing/UseCases/UseCases.json'
import styles from './Examples.module.css'

export function getExamplesSlug(label) {
  const item = examples.find((entry) => entry.label === label)
  return item ? item.slug : null
}

const ExampleCard = ({ title, desc, url, img }) => (
  <div className={styles.example}>
    <a href={url} target="_blank" rel="noreferrer">
      <img alt="screenshot" className={styles.exampleImg} src={`/assets/img/examples/${img}`} />
    </a>
    <div className="text-start">
      <a className="text-decoration-none" href={url} target="_blank" rel="noreferrer">
        <h2 className="font-18 bold mt-3 color-purple">{title}</h2>
      </a>
      <div className="text-start">
        <a className="text-decoration-none" href={url} target="_blank" rel="noreferrer">
          <h2 className="font-18 bold mt-4 color-purple">{title}</h2>
        </a>
        <div className={`${styles.exampleDesc} light-grey`}>{desc}</div>
      </div>
    </div>
  </div>
)

function getInitialTab(path) {
  const subPath = path.split('/').pop()
  return !!!subPath || subPath === 'examples' ? 'sales-marketing' : subPath
}

const Examples = ({ history }) => {
  const [active, setActive] = useState(getInitialTab(history.location.pathname))

  const onTabSelected = (slug) => {
    setActive(slug)
    history.push(`/examples/${slug}`)
  }

  return (
    <div className="body-bg full-height">
      <HomeHeader />
      <div className="container mt-5 mb-5">
        <h1 className={`${styles.title} gradient-text w-75`}>A tiiny tool with endless possibilities...</h1>
        <p className="grey text-start font-20">
          You can use tiiny to share many different types of documents, files or projects online.
        </p>
        <div className={styles.tabsContainer}>
          {examples.map((useCase, i) => (
            <button
              className={`${styles.tab} ${useCase.slug === active ? styles.activeTab : ''}`}
              onClick={() => onTabSelected(useCase.slug)}
            >
              <img
                className={`${useCase.slug === active ? styles.whiteFilter : ''} me-2 mb-1 opacity-50`}
                src={useCase.icon}
                height={15}
              />
              {useCase.label}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap align-items-center justify-content-center">
          {examples
            .find((t) => t.slug === active)
            .examples.map(({ title, url, desc, img }, i) => (
              <ExampleCard title={title} url={url} desc={desc} img={img} />
            ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Examples

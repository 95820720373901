import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

const ConfirmationModal = ({ show, message, handleNegative, handlePositive, actionProcessing }) => (
  <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleNegative} animation={false} centered>
    <Modal.Body className="p-32px font-18" dangerouslySetInnerHTML={{ __html: message }} />
    <Modal.Footer>
      {actionProcessing ? (
        <Spinner animation="border" />
      ) : (
        <React.Fragment>
          <Button variant="secondary" onClick={handleNegative}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePositive}>
            Confirm
          </Button>
        </React.Fragment>
      )}
    </Modal.Footer>
  </Modal>
)

export default ConfirmationModal

import { isDevOrPreviewSite } from '../../../utils/general'

const PRODUCT_ID_PROD = 'prod_Km934CpabavHbx'
const PRODUCT_ID_DEV = 'prod_KlpPf4MHFdUDjk'

const PRICE_ID_YEARLY_PROD = 'price_1K6aloKnX1nnNv6IB7EDiTmw'
const PRICE_ID_YEARLY_DEV = 'price_1K6HkSKnX1nnNv6IP4MfD042'

const PRICE_ID_MONTHLY_PROD = 'price_1K6aloKnX1nnNv6IqkOdkoGF'
const PRICE_ID_MONTHLY_DEV = 'price_1K6HkSKnX1nnNv6Iy2plruZf'

const PADDLE_PRICE_ID_YEARLY_PROD = 747292
const PADDLE_PRICE_ID_YEARLY_DEV = 0

const PADDLE_PRICE_ID_MONTHLY_PROD = 747291
const PADDLE_PRICE_ID_MONTHLY_DEV = 0

const PADDLE_PRICE_ID_YEARLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_YEARLY_DEV : PADDLE_PRICE_ID_YEARLY_PROD
const PADDLE_PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PADDLE_PRICE_ID_MONTHLY_DEV : PADDLE_PRICE_ID_MONTHLY_PROD

const PRODUCT_ID = isDevOrPreviewSite() ? PRODUCT_ID_DEV : PRODUCT_ID_PROD
const PRICE_ID_YEARLY = isDevOrPreviewSite() ? PRICE_ID_YEARLY_DEV : PRICE_ID_YEARLY_PROD
const PRICE_ID_MONTHLY = isDevOrPreviewSite() ? PRICE_ID_MONTHLY_DEV : PRICE_ID_MONTHLY_PROD

const PRO_U_PLAN_2 = {
  id: PRODUCT_ID,
  label: 'Pro M',
  prices: {
    yearly: {
      id: PRICE_ID_YEARLY,
      value: 444,
      paddleLink: 'https://buy.paddle.com/product/747292',
      paddleId: PADDLE_PRICE_ID_YEARLY
    },
    monthly: {
      id: PRICE_ID_MONTHLY,
      value: 49,
      paddleLink: 'https://buy.paddle.com/product/747291',
      paddleId: PADDLE_PRICE_ID_MONTHLY
    }
  },
  fileSizeLimit: 500,
  customDomainsLimit: 5
}

export default PRO_U_PLAN_2

import React from 'react'
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap'
import { copyToClipboard } from '../../../utils/general'
import UpgradeBadge from '../../UpgradeBadge/UpgradeBadge'

const ShareBox = ({ url, directFileUrl, onQRClick, type, trial, passwordProtected }) => {
  return (
    <div className="d-flex flex-column share-background align-items-center justify-items-center pb-4 pt-3 mt-4 round-8">
      <p className="font-16 share-text">Share via</p>
      <div className="d-flex align-items-center gap-2">
        <Dropdown as={ButtonGroup} size="sm">
          <Button className="custom-button" variant="light" onClick={() => copyToClipboard(url)}>
            <img height="16" width="16px" src="/assets/icons/link.svg" className="me-1" alt="Copy link" />
            Copy link
          </Button>
          {!passwordProtected && directFileUrl && type === 'PDF' && (
            <>
              <Dropdown.Toggle split variant="outline-secondary" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => copyToClipboard(directFileUrl)} disabled={trial}>
                  <span className="font-14">
                    Copy direct file link <UpgradeBadge show={trial} />
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
        <Button
          className="custom-button me-1 d-flex gap-1 align-items-center"
          variant="light"
          size="sm"
          onClick={onQRClick}
        >
          <img height="16" width="16px" src="/assets/icons/qr-code.svg" alt="QR Code" />
          QR code
        </Button>
        {/* <Button className="custom-button" variant="light" onClick={() => this.onQRCode()}>
        Email
      </Button> */}
      </div>
    </div>
  )
}

export default ShareBox

import React from 'react'
import styles from './SearchBar.module.css'

const SearchBar = ({ onSearch, show, searchTerm }) => {
  const handleInputChange = (e) => {
    const value = e.target.value
    onSearch(value)
  }

  const handleClear = () => {
    onSearch('')
  }

  if (!show) return null

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <img src="assets/icons/search.svg" height="14" alt="search_icon" className={styles.icon} />
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search..."
          className={styles.input}
        />
        {searchTerm && (
          <img
            src="assets/icons/clear-icon.svg"
            alt="search_icon"
            onClick={handleClear}
            height="24"
            className={styles.clear}
          />
        )}
      </div>
    </div>
  )
}

export default SearchBar

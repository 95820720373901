import React, { useState } from 'react'
import { Button, Modal, Accordion, Spinner } from 'react-bootstrap'
import DNSTable from '../../CustomDomainModal/components/DNSTable'
import { CopyIcon } from '../../CustomDomainModal/constants/utils.js'
import { setUpPassword } from '../../../services/custom-domain.js'

import '../CustomDomain.css'

const DOC_LINK = 'https://helpdesk.tiiny.host/en/category/custom-domains-141gxwm/'

const Details = ({ onClose, dnsData, refreshProfile }) => {
  const [loading, setLoading] = useState(false)

  const onSetUpDNS = async (domain) => {
    try {
      setLoading(true)
      await setUpPassword({ domain: domain })
      refreshProfile()
      setLoading(false)
    } catch (error) {
      alert('An error occurred. Please try again or contact support.')
      setLoading(false)
    }
  }

  const { domainRecord, show } = dnsData
  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="m-4">
        <h3>Connecting your domain</h3>
        <a target="_blank" href={DOC_LINK} rel="noreferrer">
          <Button variant="outline-secondary" size="sm">
            View documentation
          </Button>
        </a>
        <p className="grey mt-4">The following DNS records should have been added to your DNS settings:</p>
        <div className="records">
          <Accordion className={''} flush>
            <Accordion.Item eventKey={1}>
              <Accordion.Header>
                <span className="font-16">Show DNS records</span>
              </Accordion.Header>
              <Accordion.Body style={{ padding: 0 }}>
                <div className="records-con">
                  <DNSTable data={dnsData} domain={domainRecord?.domain} />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {domainRecord?.isEnomDomain && (
              <Accordion.Item eventKey={2}>
                <Accordion.Header>
                  <span className="font-16">Manage DNS Records</span>
                </Accordion.Header>
                <Accordion.Body className="p-3">
                  <p className="mt-2 font-8">
                    You can manage your DNS host records with the following login credentials:
                  </p>

                  <div className="manage-dns-con">
                    <div className="flex">
                      <p>
                        <b>Login link</b>:{' '}
                        <a href="https://access.enom.com" target="_blank" rel="noreferrer">
                          https://access.enom.com
                        </a>
                      </p>
                    </div>
                    <div>
                      <b>Domain:</b> <span>{domainRecord?.domain}</span>
                    </div>
                    <div className="flex">
                      <p>
                        <b>Password</b>:{' '}
                        <i>{domainRecord?.dnsPassword ? domainRecord.dnsPassword : 'No password set'}</i>
                      </p>
                      {domainRecord?.dnsPassword && (
                        <CopyIcon text={domainRecord.dnsPassword} customStyle="flex mx-2" />
                      )}
                    </div>

                    {!domainRecord?.dnsPassword && (
                      <div className="flex justify-content-end">
                        <Button variant="outline-secondary" size="sm" onClick={() => onSetUpDNS(domainRecord?.domain)}>
                          {loading ? <Spinner animation="border" size="sm" /> : 'Generate new password'}
                        </Button>
                      </div>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
      </div>
      <Button className="ms-4 me-4 mb-4" onClick={onClose}>
        Done
      </Button>
    </Modal>
  )
}

export default Details

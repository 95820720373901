import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ProgressBarLoader from '../ProgressBar/ProgressBar'

import './LoadingBox.css'

export const LoadingBox = ({ className = '', showProgressBar, progress, message = 'Working magic...' }) => {
  return (
    <div className={`loading-box ${className}`}>
      {showProgressBar ? (
        <ProgressBarLoader now={progress} progressBarMessage={message} />
      ) : (
        <>
          <Spinner animation="border" />
          <div className="mt-3">{message}</div>
        </>
      )}
    </div>
  )
}

import { useEffect } from 'react'

export default function UserFeedbackModal() {
  const loadEmbedScript = () => {
    if (document.getElementById('inPiSc')) {
      // console.log('InsightPipeline script already loaded')
      return
    }

    const script = document.createElement('script')
    script.src = 'https://app.insightpipeline.com/embed.js'
    script.type = 'text/javascript'
    script.async = true
    script.id = 'inPiSc'

    script.onload = () => {
      window.InsightPipeline?.load({
        app_id: '5f366def-4970-4f06-b0ad-ea5f239b5408'
      })
    }

    document.body.appendChild(script)

    // Clean up script element on component unmount
    return () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    // 1. Load the script on the page
    loadEmbedScript()
  }, [])

  return <></>
}

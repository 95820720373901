import React from 'react'
import { Button } from 'react-bootstrap'

const SuccessView = ({ history, onHide }) => (
  <div>
    <div>
      <div className="color-purple mb-2 font-22">Account upgraded</div>
      <img className="me-3" height="25px" width="25px" alt="check" src="/check.png" />
      Your account has been successfully upgraded.
    </div>
    <Button
      className="float-right mt-1"
      onClick={() => {
        history.push('/'), onHide()
      }}
    >
      Done
    </Button>
  </div>
)

export default SuccessView

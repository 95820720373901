import React, { Component } from 'react'
import axios from 'axios'
import { Button, Form } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { withRouter } from 'react-router'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { connect } from 'react-redux'

import { API_ROOT, REGEX_EMAIL } from '../../constants'
import { STAGE_SUCCESS } from '../../Landing/Home/Home'
import { trackUpload } from '../../utils/analytics'
import { PLANS, PLANS_ID } from '../../constants/plans'
import {
  setDraggedFile,
  setPreviewData,
  setPreviewModal,
  setQrCodeData,
  setQrCodeModal,
  showCreateSiteModal,
  toggleModal
} from 'Manage/actions'
import ShareBox from './components/ShareBox'
import { getDirectFileLink } from '../../utils/general'
import { STATE_READY } from '../CreateUpdateSiteModal/interface'
import { setShowPaymentModal, showUpgradeCardModal, UPGRADE_QR_PAYLOAD } from '../../actions'
import { QR_PAYWALL_DATE } from '../QRCodeModal/QRCodeModal'
import { errors } from '../../constants'

import './SuccessBox.css'

class SuccessBoxHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      domainOwned: props.recordedEmail
    }
  }

  componentDidMount() {
    const { uploaded: { type, size } = {}, userProfile } = this.props
    trackUpload(PLANS_ID[userProfile?.productId]?.label || 'Free', size, type, userProfile?.email)
    this.props.dispatch(setDraggedFile(null))
    this.props.dispatch(setQrCodeData(this.props.domain))
  }

  componentWillUnmount() {
    this.props.dispatch(setDraggedFile(null))
    this.props.dispatch(setQrCodeData(null))
  }

  onFieldChange = (field, value) => this.setState({ [field]: value })

  onKeep = async () => {
    const { email } = this.state

    const isValid = REGEX_EMAIL.test(email.toLowerCase()) && email.toLowerCase().search('mailinator') === -1
    if (!isValid) {
      this.setState({ invalidEmail: true })
      return
    } else {
      this.setState({ invalidEmail: false })
    }

    this.setState({ isFetching: true })

    const recaptchaValue = await this.props.googleReCaptchaProps.executeRecaptcha('homepage')
    const headers = {
      'x-recaptcha': recaptchaValue
    }

    axios
      .post(
        `${API_ROOT}/create-trial-account`,
        {
          subdomain: this.props.domain.split('.')[0],
          tld: `.${this.props.domain.split('.').slice(1).join('.')}`,
          email
        },
        {
          headers: {
            'Content-Type': 'application/json',
            ...headers
          }
        }
      )
      .then((res) => {
        this.props.onFieldChange('stage', STAGE_SUCCESS)
        this.setState({ isFetching: false })
        if (res.data.success) {
          this.setState({
            domainOwned: res.data.success,
            updateCode: res.data.updateCode
          })
        }
      })
      .catch((err) => {
        this.setState({
          isFetching: false,
          notOwner: err.response.data.code === errors.NOT_OWNER
        })
      })
  }

  getExpiryLabel = (expiry, neverExpires) => {
    if (neverExpires) {
      return 'is live'
    } else if (expiry) {
      return `is live for the next ${expiry} days`
    } else {
      return 'is live for 24 hours'
    }
  }

  onQRCode = () => {
    const { dispatch, userProfile, domain } = this.props

    if (userProfile.productId) {
      dispatch(setQrCodeModal(true))
      dispatch(setQrCodeData(domain))
    } else {
      dispatch(
        showUpgradeCardModal({
          ...UPGRADE_QR_PAYLOAD,
          onClick: () => dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: PLANS.TINY.id }))
        })
      )
    }
  }

  onClose = () => {
    this.props.dispatch(showCreateSiteModal(false))
    this.props.dispatch(toggleModal(false))

    if (this.props.onClose) this.props.onClose()
  }

  onUpdateSite = () => {
    this.props.setRootState({
      stage: STATE_READY,
      uploaded: undefined
    })
  }

  onViewSite = (domain) => {
    if (domain) window.open(`https://${domain}`, '_blank')
    else {
      this.props.dispatch(setPreviewModal(true))
      this.props.dispatch(setPreviewData(domain))
    }
  }

  render() {
    const { domainOwned, isFetching, notOwner, invalidEmail } = this.state
    const {
      domain,
      showUpdateSuccessView,
      hideUpgradeView,
      uploaded,
      userProfile,
      expiry,
      neverExpires,
      showUpdateButton
    } = this.props

    return (
      <div className="success-box">
        <div onClick={this.onClose}>
          <img className="exit" src="delete.png" alt="quit" />
        </div>
        <h3>Success</h3>
        <img className="celebrate" src="celebrate.gif" alt="celebrate" />
        <div>
          {domain ? (
            <h5 className="domain">
              <a href={`https://${domain}`} target="_blank" rel="noreferrer">
                {domain}
              </a>
            </h5>
          ) : (
            <h5>Your Site</h5>
          )}
          <h6>
            <p>{this.getExpiryLabel(expiry, neverExpires)}</p>
          </h6>
          <div className="d-flex flex-column mt-2 mb-3 align-items-center">
            <div className="mt-2 d-flex align-items-center flex-row">
              {showUpdateButton && (
                <Button className="me-2" variant="outline-primary" onClick={this.onUpdateSite}>
                  Update
                </Button>
              )}
              <Button onClick={() => this.onViewSite(domain)}>View Site</Button>
            </div>
            {showUpdateButton && domain && (
              <>
                <ShareBox
                  passwordProtected={uploaded.passwordProtected}
                  trial={!!!userProfile.productId}
                  url={`https://${domain}`}
                  directFileUrl={getDirectFileLink(
                    `https://${domain}`,
                    uploaded.filename,
                    'pdf',
                    uploaded.lastUpdated || uploaded.created
                  )}
                  onQRClick={this.onQRCode}
                  type={uploaded.type}
                />
                <div className="d-flex align-items-center justify-items-center mt-3 gap-1 font-small">
                  <span className="d-inline">Something broken? -</span>
                  <a
                    className="d-inline link"
                    href="https://helpdesk.tiiny.host/en/category/common-issues-wocoo1/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read our help guide
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        {!hideUpgradeView && (
          <>
            {isFetching ? (
              <Spinner className="mt-5" animation="border" />
            ) : (
              <div className="container-lock-site">
                {notOwner && <div>Sorry, that link name is taken</div>}
                <div>
                  <div>
                    <div className="bold">Own & extend</div>
                    <div className="font-small op-7">Create an account to edit & manage your link.</div>
                    {!domainOwned && !showUpdateSuccessView && (
                      <Form.Group className="mb-0 mt-3">
                        <div className="flex justify-center">
                          <Form.Control
                            style={{ width: '230px' }}
                            onChange={(e) => this.onFieldChange('email', e.target.value)}
                            placeholder="Email"
                            aria-label="email"
                            type="email"
                          />
                          <Button className="ms-2" onClick={this.onKeep}>
                            Sign up
                          </Button>
                        </div>
                        {invalidEmail && <div className="validation-error">Oops, that's not a valid email</div>}
                        <div className="font-small grey mt-2 ms-2">We only send relevant emails</div>
                      </Form.Group>
                    )}
                    {domainOwned && (
                      <div className="mt-3">
                        <img height="20px" alt="check" src="check.png" />
                        <b> We've emailed you a login link</b>
                        <div className="link" onClick={() => this.setState({ domainOwned: false })}>
                          Try again
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile
})
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(SuccessBoxHomePage)))

import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import StyledDropzone, { DROPZONE_PDF_TYPE, DROPZONE_PHP_TYPE, DROPZONE_WEBSITE_TYPE } from '../StyledDropzone'
import TemplateSelector from '../TemplateSelector/TemplateSelector'
import { DROPZONE_ACCEPTED_FILES } from '../../constants'

const contentTypes = {
  resume: 'pdf',
  pdf: 'pdf',
  html: 'html',
  php: 'php',
  template: 'template'
}

export const ZIP_TYPE = 'ZIP_TYPE'
export const PDF_TYPE = 'PDF_TYPE'
export const HTML_TYPE = 'HTML_TYPE'
export const ALL_TYPES = 'ALL_TYPES'

const getContentTypes = (
  type,
  defaultKey,
  onDrop,
  onTemplateSelected,
  handleTabSelect,
  activeTab,
  goToTab,
  acceptedFiles
) => {
  if (type === ALL_TYPES) {
    return (
      <Tabs
        id="content-selector"
        defaultActiveKey={defaultKey}
        activeKey={activeTab}
        onSelect={handleTabSelect}
        className="mb-3 justify-center"
      >
        <Tab eventKey="html" title="HTML">
          <StyledDropzone
            className="tr-landing-upload-file-zone"
            onDrop={onDrop}
            onTemplateSelected={onTemplateSelected}
            type={DROPZONE_WEBSITE_TYPE}
            goToTemplateTab={() => goToTab('template')}
            acceptedFiles={acceptedFiles}
          />
        </Tab>
        <Tab eventKey="pdf" title="PDF" className="tr-pdf-tab">
          <StyledDropzone
            className="tr-landing-upload-file-zone"
            onDrop={onDrop}
            onTemplateSelected={onTemplateSelected}
            type={DROPZONE_PDF_TYPE}
            goToTemplateTab={() => goToTab('template')}
            acceptedFiles={acceptedFiles}
          />
        </Tab>
        <Tab eventKey="php" title="PHP" className="tr-php-tab">
          <StyledDropzone
            className="tr-landing-upload-file-zone"
            onDrop={onDrop}
            onTemplateSelected={onTemplateSelected}
            type={DROPZONE_PHP_TYPE}
            goToTemplateTab={() => goToTab('template')}
            acceptedFiles={acceptedFiles}
          />
        </Tab>
        <Tab eventKey="zip" title="ZIP" className="tr-zip-tab">
          <StyledDropzone
            className="tr-landing-upload-file-zone"
            onDrop={onDrop}
            onTemplateSelected={onTemplateSelected}
            type="ZIP"
            goToTemplateTab={() => goToTab('template')}
            acceptedFiles={acceptedFiles}
          />
        </Tab>
        <Tab eventKey="template" title="Examples" className="tr-templates-tab">
          <TemplateSelector onClick={onTemplateSelected} />
        </Tab>
      </Tabs>
    )
  } else if (type === HTML_TYPE) {
    return (
      <StyledDropzone
        className="tr-landing-upload-file-zone"
        onDrop={onDrop}
        onTemplateSelected={onTemplateSelected}
        hideTemplate
        acceptedFiles={acceptedFiles}
      />
    )
  }
}

const ContentSelector = ({
  className,
  onDrop,
  onTemplateSelected,
  type = ALL_TYPES,
  acceptedFiles = DROPZONE_ACCEPTED_FILES
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const defaultKey = contentTypes[urlParams.get('content')] || 'html'
  const [activeTab, setActiveTab] = useState(defaultKey)

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  const goToTab = (tabKey) => {
    setActiveTab(tabKey)
  }

  return (
    <div className={`container-dropzone ${className || ''}`}>
      {getContentTypes(
        type,
        defaultKey,
        onDrop,
        onTemplateSelected,
        handleTabSelect,
        activeTab,
        goToTab,
        acceptedFiles
      )}
    </div>
  )
}

export default ContentSelector

import { isDevOrPreviewSite } from '../../utils/general'

export const PRO_PLAN_PRICES = {
  yearly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcRkKnX1nnNv6Iv9a19omc' : 'price_1PVckKKnX1nnNv6I0RlD65bk',
      currency: '€',
      value: 372
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcSqKnX1nnNv6IoRh7yoLC' : 'price_1PVckuKnX1nnNv6IlIl8w3Dp',
      currency: '£',
      value: 300
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1KfVUXKnX1nnNv6IRgoMTn6b' : 'price_1KfVHbKnX1nnNv6I4wEA7d2K',
      currency: '$',
      value: 372
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcTVKnX1nnNv6IQcFVtzyM' : 'price_1Q7FMRKnX1nnNv6IAxKI4Anp',
      currency: '₹',
      value: 15600
    }
  },
  monthly: {
    EU: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcVsKnX1nnNv6IPKAeOd9O' : 'price_1PVcowKnX1nnNv6Isn1XgfqU',
      currency: '€',
      value: 38
    },
    UK: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcWGKnX1nnNv6Iv0IzCrY0' : 'price_1PVcpOKnX1nnNv6I9EmmjNRt',
      currency: '£',
      value: 30
    },
    US: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1KfVScKnX1nnNv6IzFaboaWJ' : 'price_1KfV77KnX1nnNv6Iw1tVuVWl',
      currency: '$',
      value: 38
    },
    INDR: {
      stripePriceId: isDevOrPreviewSite() ? 'price_1PVcVMKnX1nnNv6IVpgM42Mg' : 'price_1Q7FPJKnX1nnNv6IUvwV8GTD',
      currency: '₹',
      value: 1600
    }
  }
}

import React, { useState } from 'react'
import InitialStage from './components/InitialStage'
import ConfirmationStage from './components/ConfirmationStage'
import { ErrorBox } from '../ErrorBox/ErrorBox'

const Cancel = ({ onHide, sites, onUpgrade, visitorCount, currentPlanId }) => {
  const [hasError, setHasError] = useState(false)
  const [stage, setStage] = useState('initial')

  if (hasError) {
    return <ErrorBox onClick={() => setHasError(false)} />
  }

  if (stage === 'initial') {
    return (
      <InitialStage
        sites={sites}
        onHide={onHide}
        visitorCount={visitorCount}
        currentPlanId={currentPlanId}
        onContinueToCancellation={() => setStage('cancellation')}
      />
    )
  }

  return (
    <ConfirmationStage
      sites={sites}
      onUpgrade={onUpgrade}
      onBack={() => setStage('initial')}
      onHide={onHide}
      setHasError={setHasError}
    />
  )
}

export default Cancel

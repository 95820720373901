import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { MODES } from 'components/CreateUpdateSiteModal/interface'
import UpgradeBadge from '../../../../UpgradeBadge/UpgradeBadge'

const SelectItem = ({ label, trial, learnLink, onUpgrade }) => (
  <>
    <Dropdown.Item className="flex pt-2 pb-2" eventKey={label} style={{ whiteSpace: 'wrap' }} disabled={trial}>
      <img
        className={`me-3 mt-1 ${trial ? 'opacity-25' : 'opacity-75'}`}
        src={`assets/icons/${MODES[label].icon}`}
        height={20}
      />
      <div>
        <div>{label}</div>
        <div className="font-small grey">{MODES[label].desc}</div>
      </div>
    </Dropdown.Item>
    <div>
      {trial && (
        <div className="mb-3" style={{ marginLeft: 46 }}>
          <UpgradeBadge show={trial} onUpgrade={onUpgrade} />
          <a
            href={learnLink}
            target="_blank"
            className="ms-1 text-decoration-underline font-small black"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      )}
    </div>
  </>
)

export default SelectItem

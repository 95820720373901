export const integrations = {
  canva: {
    name: 'Canva',
    logo: 'canva.svg', // Assuming SVG in assets folder
    url: 'www.canva.com',
    privacyPolicyUrl: 'https://about.canva.com/privacy-policy/',
    termsAndConditionsUrl: 'https://about.canva.com/terms-of-use/',
    desc: 'Create beautiful designs from thousands of professional templates'
  },
  vscode: {
    name: 'Vscode',
    logo: 'vscode.png', // Assuming SVG in assets folder (replace with your logo)
    url: 'code.visualstudio.com',
    privacyPolicyUrl: 'https://privacy.microsoft.com/en-us/privacystatement', // Replace with appropriate URL
    termsAndConditionsUrl: 'https://www.microsoft.com/en-us/legal/terms-of-use', // Replace with appropriate URL
    desc: `Code editing. Redefined. Free. Built on open source. Runs everywhere.`
  }
}

export const getIntegrationData = (type) => {
  const integration = integrations[type.toLowerCase()]

  if (!integration) {
    throw new Error(`Unsupported integration type: ${type}`)
  }
  return integration
}

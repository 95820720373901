export const FREE_PLAN_PRICES = {
  yearly: {
    EU: {
      currency: '€',
      value: 0
    },
    UK: {
      currency: '£',
      value: 0
    },
    US: {
      currency: '$',
      value: 0
    },
    INDR: {
      currency: '₹',
      value: 0
    }
  },
  monthly: {
    EU: {
      currency: '€',
      value: 0
    },
    UK: {
      currency: '£',
      value: 0
    },
    US: {
      currency: '$',
      value: 0
    },
    INDR: {
      currency: '₹',
      value: 0
    }
  }
}

import React, { useState } from 'react'
import { Nav, Navbar, NavDropdown, Modal } from 'react-bootstrap'
import { withRouter } from 'react-router'
import Sticky from 'react-stickynode'
import { connect } from 'react-redux'

import BorderButton from '../BorderButton/index.b'
import { accountModal, showUpgradeModal, toggleTeamModal, toggleSignUpModal } from '../../Manage/actions'
import { PLANS } from '../../constants/plans'
import CTAButton from '../CTAButton/CTAButton'
import SignUpPage from 'Login/SignUpModal'
import { openPricingPopup } from 'utils/sendy'
import { resetUserProfile } from '../../Manage/actions'
import { isMac } from '../../utils/general'

import './HomePageHeader.css'

const onManageClick = (history) => {
  if (localStorage.getItem('token')) {
    history.push('/manage')
  } else {
    history.push('/login')
  }
}

const logout = (history, dispatch) => {
  localStorage.removeItem('token')
  dispatch(resetUserProfile())
  history.push('/login')
}

const ManageAccountButtons = ({ isChildAccount, onAddTeam }) => (
  <>
    {!isChildAccount && (
      <button className="home-header-btn tr-add-team p-2" onClick={onAddTeam}>
        <img style={{ opacity: 0.5 }} className="me-2" src="/assets/icons/add-user.png" height={15} />
        Add team
      </button>
    )}
  </>
)

const UpgradeButton = ({ dispatch }) => (
  <BorderButton
    className="tr-header-upgrade-btn"
    onClick={() => {
      dispatch(showUpgradeModal(true))
      openPricingPopup()
    }}
    label="Upgrade for more"
  />
)

const getAccountButton = (trialAccount, isChildAccount, dispatch, onAddTeam) => {
  if (trialAccount) {
    return <UpgradeButton dispatch={dispatch} />
  } else if (isChildAccount) {
    return null
  } else {
    return <ManageAccountButtons dispatch={dispatch} isChildAccount={isChildAccount} onAddTeam={onAddTeam} />
  }
}

const HomeHeader = ({
  dispatch,
  history,
  proMode,
  productId,
  isLoading,
  isChildAccount,
  showUpgradePayModal,
  showDeleteAccountModal,
  showSignUpModal
}) => {
  const [sticky, setSticky] = useState(false)

  const trialAccount = !productId

  const handleStateChange = (status) => {
    setSticky(status.status === Sticky.STATUS_FIXED)
  }

  const onAddTeam = () => {
    if (productId === PLANS.PRO_U.id) {
      dispatch(toggleTeamModal(true))
    } else {
      showUpgradePayModal()
    }
  }

  const isLoginPage = window.location.pathname === '/login'

  return (
    <Sticky innerClass={sticky ? 'sticky' : ''} onStateChange={handleStateChange} innerZ={999}>
      <Navbar bg="white" expand="lg" collapseOnSelect>
        <Navbar.Toggle className="ms-3" aria-controls="responsive-navbar-nav" />
        <Navbar.Brand className="d-l-none d-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        {!proMode && (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-l-3">
              <Nav.Link className="menu-link" href="/blog/">
                Blog
              </Nav.Link>
              <Nav.Link className="menu-link" onClick={() => history.push('/help')}>
                Help
              </Nav.Link>
              <Nav.Link className="menu-link" onClick={() => history.push('/pricing')}>
                Pricing
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}
        <Navbar.Brand className="d-none d-l-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          {!proMode && <Nav className="me-auto" />}
          {proMode ? (
            <>
              <Nav className="me-auto" activeKey="/none">
                <Nav.Item>
                  <Nav.Link className="header-link float-left" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
                    Support
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="header-link float-left" target="_blank" href="https://helpdesk.tiiny.host/">
                    Documentation
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <NavDropdown className="p-md-0 m-auto" title="Integrations" variant="link">
                    <NavDropdown.Item href="https://zapier.com/apps/tiiny-host/integrations" target="_blank">
                      Zapier
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="https://chromewebstore.google.com/detail/tiny-host/meippdeigckopliglcajepbiaoaccflc"
                      target="_blank"
                    >
                      Google Chrome
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="https://marketplace.visualstudio.com/items?itemName=TiinyHost.tiinyhost"
                      target="_blank"
                    >
                      VS Code
                    </NavDropdown.Item>
                    {isMac() && (
                      <NavDropdown.Item href="https://tiiny.host/mac/plugin.dmg" target="_blank">
                        Mac OS
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </Nav.Item>
              </Nav>
              <Nav>
                {!isLoading && (
                  <Nav.Link>{getAccountButton(trialAccount, isChildAccount, dispatch, onAddTeam)}</Nav.Link>
                )}
                <NavDropdown
                  className="account-btn p-md-0 m-auto"
                  title={
                    <span>
                      <img
                        src="/assets/icons/profile-user.svg"
                        className="me-2 opacity-50"
                        height={25}
                        width={25}
                        alt="account"
                      />
                      Account
                    </span>
                  }
                >
                  {!isChildAccount && (
                    <>
                      <NavDropdown.Item onClick={() => dispatch(accountModal(true))}>Manage account</NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {trialAccount && (
                    <>
                      <NavDropdown.Item onClick={showDeleteAccountModal}>Delete account</NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  )}
                  <NavDropdown.Item onClick={() => window.open('https://tiiny.host/affiliates', '_blank')}>
                    Affiliate Programme
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logout(history, dispatch)}>Log out</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </>
          ) : !isLoginPage ? (
            <Nav>
              <Nav.Link>
                <CTAButton
                  className="m-auto"
                  label="Log in"
                  onClick={() => onManageClick(history)}
                  size="small"
                  variant="secondary-round"
                  showArrow={false}
                />
              </Nav.Link>
              <Nav.Link>
                <CTAButton
                  className="m-auto"
                  label="Sign up free"
                  onClick={() => dispatch(toggleSignUpModal(true))}
                  size="small"
                  variant="primary-gradient-round"
                />
              </Nav.Link>
            </Nav>
          ) : null}
        </Navbar.Collapse>
      </Navbar>
      <Modal show={showSignUpModal} onHide={() => dispatch(toggleSignUpModal(false))} centered>
        <div className="container p-3 ">
          <SignUpPage />
        </div>
      </Modal>
    </Sticky>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })
const mapStateToProps = (state) => ({
  productId: state.manage.userProfile.productId,
  isLoading: !state.manage.userProfile.email,
  isChildAccount: !!state.manage.userProfile.childAccount,
  email: state.manage.userProfile.email,
  showSignUpModal: state.manage.showSignUpModal
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeHeader))

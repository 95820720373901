import React from 'react'
import moment from 'moment'
import { Button, ProgressBar } from 'react-bootstrap'
import { ToolTip } from '../../../components/ProPlans/PlanCards'
import { FREE_PLAN, PLANS, PLANS_ID } from '../../../constants/plans'
import { formatMbToGb, onUpgradePlan } from '../../../utils/general'

import styles from './Bandwidth.module.css'
import { useDispatch } from 'react-redux'

// Show bandwidth or page views
function LimitBar({ pageViews, bandwidth, productId, resetDate }) {
  const plan = PLANS_ID[productId] || FREE_PLAN
  const bandwidthGB = formatMbToGb({ mb: bandwidth })
  const showBandwidth = bandwidthGB / plan.bandwidthLimit > pageViews / plan.visitorLimit

  return (
    <>
      <ProgressBar
        className={`${styles.progressBar} mt-3`}
        now={(showBandwidth ? bandwidthGB / plan.bandwidthLimit : pageViews / plan.visitorLimit) * 100}
        variant="danger"
      />
      <div className="text-end mt-1">
        <b>{showBandwidth ? bandwidthGB : pageViews}</b> /{' '}
        {showBandwidth ? `${plan.bandwidthLimit} GB` : `${plan.visitorLimit} visitors`}{' '}
        <span className="font-small"> per month</span>{' '}
        <ToolTip text={`Resets - ${moment(resetDate).add(1, 'months').format('MMMM Do YYYY')}`} />
      </div>
    </>
  )
}

function BandwidthAlert({ pageViews, bandwidth, productId, resetDate, usage_alert = 0 }) {
  const dispatch = useDispatch()
  if (usage_alert === 0) return null // TODO: reset this on upgrade
  const onUpgradeClick = () => {
    if (productId === PLANS.PRO_U.id) {
      window.$crisp.push(['do', 'chat:open'])
    } else {
      onUpgradePlan({ bandwidth: bandwidth }, dispatch)
    }
  }

  return (
    <div className={`${styles.container} p-4 mb-5`}>
      <div className="text-start font-20 bold">
        <img src="/assets/icons/warning_black.svg" height={20} width={20} className="me-2" />
        Warning: you're reaching your monthly account limit
      </div>
      <LimitBar pageViews={pageViews} bandwidth={bandwidth} productId={productId} resetDate={resetDate} />
      <div className="flex gap-2">
        <div className="font-18 text-start flex-grow-1">
          Please upgrade your account or{' '}
          <a className="link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
            contact support
          </a>{' '}
          to avoid disruption.
        </div>
        <div>
          <Button className="ms-auto d-block mt-4 bold" variant="warning" onClick={onUpgradeClick}>
            UPGRADE
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BandwidthAlert

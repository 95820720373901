import React from 'react'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'

import styles from './UploadDropDown.module.css'

const UploadDropdown = ({ onSiteAction, onShowCodeEditor, onOpenTextEditor }) => (
  <Dropdown as={ButtonGroup} className={styles.button}>
    <Button
      className="flex align-items-center justify-content-center no-box-shadow fs-6 lh-sm"
      variant="primary"
      onClick={() => onSiteAction('CREATE')}
    >
      <img className="me-2 ms-1 img" src="/assets/icons/cloud-arrow-up.svg" height={24} />
      <span className="text">Upload file</span>
    </Button>
    <Dropdown.Toggle split variant="primary" id="dropdown-custom-2" className="no-box-shadow" />
    <Dropdown.Menu className="super-colors">
      <Dropdown.Item className="flex" eventKey="1" onClick={onShowCodeEditor}>
        <img className="me-2 mt-1 opacity-75" src="/assets/icons/code-block.svg" height={24} />
        <span className="text">Paste HTML</span>
      </Dropdown.Item>
      <Dropdown.Item className="flex" eventKey="2" onClick={onOpenTextEditor}>
        <img className="me-2 mt-1 opacity-75" src="/assets/icons/text.svg" height={24} />
        <span className="text">Write text</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

export default UploadDropdown

import React, { Component } from 'react'
import { getErrorMessage } from '../../utils/general'
import * as Sentry from '@sentry/react'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error) {
    const errorMessage = getErrorMessage(error)
    return { hasError: true, errorMessage: errorMessage }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {
      tags: {
        section: 'manage-livesites-table-error'
      }
    })
  }

  render() {
    if (this.state.hasError) {
      const { fallback: FallbackComponent } = this.props
      return <FallbackComponent message={this.state.errorMessage} />
    }

    return this.props.children
  }
}

export default ErrorBoundary

import { API_ROOT } from '../../constants'
import api from '../../utils/api'

/** @todo: ability to update full site from 1 v2 endpoint, remain : uploadValues */
export const updateSiteContents = async (domain, body, config, uploadProgress) =>
  await api.post(`${API_ROOT}/v2/pro/site/update?domain=${domain}`, body, config, (event) => {
    const { total, loaded } = event
    uploadProgress({ total, loaded }, { size: total })
  })

export const createSite = async (body, config, uploadProgress) =>
  await api.post(`${API_ROOT}/v2/pro/site/create`, body, config, (event) => {
    const { total, loaded } = event

    uploadProgress({ total, loaded }, { size: total })
  })

export const deleteSiteFiles = async (body, config) =>
  await api.post(`${API_ROOT}/v2/pro/site/files/delete`, body, config)

export const getSiteContents = async (body, config) => await api.get(`${API_ROOT}/v2/pro/site`, body, config)

export const getSites = async (body, config) => await api.post(`${API_ROOT}/v2/pro/site/webhook`, body, config)

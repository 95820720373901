import SuccessBox from 'components/CreateUpdateSiteModal/components/SuccessBox'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ShareModal = ({ show, onClose, userProfile, site, files, onReset }) => {
  const trialAccount = !userProfile.productId

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={trialAccount ? 'lg' : 'md'}
      className="success_modal"
    >
      <SuccessBox userProfile={userProfile} site={site} files={files} onClose={onClose} onReset={onReset} />
    </Modal>
  )
}

export default ShareModal

import React from 'react'
import styles from '../CustomDomainModal.module.css'
import { getFirstPart, CopyIcon } from '../constants/utils'
import CustomTooltip from 'components/CustomTooltip'

const DNSTable = ({ data, domain }) => {
  return (
    <>
      {data &&
        data?.records &&
        data?.records.map((record, index) => {
          const suffix = record?.type === 'A' ? `${domain}` : `${getFirstPart(record?.host)}.${domain}`
          return (
            <div className={styles.table} key={index}>
              <div className={styles.record}>
                <span className={styles.dnsLabel}>Type:</span>
                <span className={styles.dnsValue}>
                  {record?.type} <CopyIcon text={record?.type} />
                </span>
              </div>
              <div className={styles.record}>
                <span className={styles.dnsLabel}>Name/Host:</span>
                <span className={styles.dnsValue}>
                  {getFirstPart(record?.host)} <CopyIcon text={getFirstPart(record?.host)} />
                </span>
              </div>
              <div className={styles.record}>
                <span className={styles.dnsLabel}>Value:</span>
                <span className={styles.dnsValue}>
                  {record?.value}
                  <CopyIcon text={record?.value} />
                </span>
              </div>
              <span className={styles.test}>
                <CustomTooltip label="Click 'Search' on DNSChecker.org & verify the records match EXACTLY">
                  <a href={`https://dnschecker.org/#${record?.type}/${suffix}`} target="_blank" rel="noreferrer">
                    Test connection →
                  </a>
                </CustomTooltip>
              </span>
            </div>
          )
        })}
    </>
  )
}

export default DNSTable

import api from '../utils/api'
import { API_ROOT } from '../constants'

export const trackReferral = (email) => {
  api.post(`${API_ROOT}/track-referral`, { email }).then((result) => {
    if (result.error) {
      throw result
    }
    localStorage.removeItem('referral')
  })
}

import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import CustomTable from '../CustomTable'
import {
  copyToClipboard,
  getDirectFileLink,
  hasFeature,
  sortDuplicateSites,
  sortLoadingSites
} from '../../utils/general'
import { setDraggedFile, toggleModal, setLoadingSitesStore } from 'Manage/actions'
import { F_EDIT_MODE } from '../../constants/plans/constants'
import { ACTION_UPDATE, FILE_TYPE_PDF } from '../CreateUpdateSiteModal/interface'
import { API_ROOT } from '../../constants'
import { getSafeFileName } from 'utils/file'
import './LiveSitesTable.css'
import { getSites } from 'services/v2/pro'
import { setShowPaymentModal } from 'actions'
import { PLANS } from 'constants/plans'
import UploadDropdown from '../UploadDropDown'
import UpdateActionDropdown from './components/UpdateActionDropdown'
import useCheckIsMobileScreen from '../../hooks/checkMobileScreen'
import { isDevSite } from '../../utils/general'

import styles from './LiveSitesTable.module.css'

const getDomain = (siteRecord) => siteRecord.subdomain || siteRecord.link

function getDirectFileLinkForSite(s) {
  if (s?.linkStatus != 'processing' && s?.linkStatus !== 'error')
    if (s.type === FILE_TYPE_PDF) {
      return `https://${getDomain(s)}/${getSafeFileName(s.filename)}`
    } else {
      // legacy links
      return getDirectFileLink(`https://${getDomain(s)}`, s.filename, 'pdf', s.lastUpdated || s.created)
    }
}

const LiveSitesTable = ({
  sites,
  onSiteAction,
  userProfile,
  onArchive,
  onDelete,
  onDownload,
  onAnalytics,
  onQRCode,
  onEditPDF,
  onEmbed,
  onPreview,
  onShare,
  onOpenTextEditor,
  onAddCustomDomain,
  badge,
  onShowCodeEditor
}) => {
  const { isMobile, isTablet } = useCheckIsMobileScreen()
  const dispatch = useDispatch()
  const [allSites, setAllSites] = useState([])
  const [onlineSites, setOnlineSites] = useState([])
  const [loadingSites, setLoadingSites] = useState([])
  const [webhook, setWebhook] = useState(false)
  const [webhookId, setWebhookId] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  /** Webhook for processing sites */
  useEffect(() => {
    return () => {
      if (webhook === true) deleteWebhook()
    }
  }, [])

  useEffect(() => {
    setAllSites(sites)
  }, [sites])

  useEffect(() => {
    setLoadingSites(sortLoadingSites(allSites))
  }, [allSites])

  useEffect(() => {
    dispatch(setLoadingSitesStore(loadingSites))
  }, [loadingSites])

  useEffect(() => {
    if (loadingSites?.length > 0) {
      setWebhook(true)
    } else if (loadingSites?.length === 0) {
      setWebhook(false)
    }
  }, [loadingSites])

  useEffect(() => {
    if (onlineSites?.length > 0) {
      setAllSites(sortDuplicateSites(allSites, onlineSites))
      setOnlineSites([])
    }
  }, [onlineSites])

  useEffect(() => {
    if (webhook === true) {
      createWebhook(loadingSites)
    } else if (webhook === false) {
      deleteWebhook()
    }
  }, [webhook])

  const getSiteWebhook = async () => {
    const { data } = await getSites({ sites: loadingSites })

    if (data?.sites) {
      let myOnlineSites = []

      data.sites.map((site) => {
        if (site.linkStatus === 'online' || site.linkStatus === 'error') {
          myOnlineSites.push(site)
        }
      })

      setLoadingSites(sortLoadingSites(data.sites))

      if (myOnlineSites.length > 0) {
        setOnlineSites(myOnlineSites)
      }
    }
  }

  const createWebhook = () => {
    const intervalId = setInterval(getSiteWebhook, 20000)

    setWebhookId(intervalId)
  }

  const deleteWebhook = () => {
    clearInterval(webhookId)
    setWebhookId('')
  }

  const handleUpdateClick = (s) => {
    dispatch(setDraggedFile(null))
    dispatch(toggleModal(true))
    onSiteAction(ACTION_UPDATE, s)
  }

  const onEditHTML = (domain, isTrial) => {
    if (isDevSite()) {
      window.open(
        `https://www.tiiny.co.uk/editor/?domain=${domain}&file=/index.html${isTrial ? '&trial=true' : ''}`,
        '_blank'
      )
    } else {
      window.open(
        `https://tiiny.host/editor/?domain=${domain}&file=/index.html${isTrial ? '&trial=true' : ''}`,
        '_blank'
      )
    }
  }

  const handleEditPdfWithPSWD = (password, fileLink) => {
    if (password) {
      const token = btoa(password)
      const proxyUrl = `${API_ROOT}/set-cookie-and-proxy?url=${encodeURIComponent(fileLink)}&token=${encodeURIComponent(
        token
      )}`
      onEditPDF(proxyUrl)
    } else {
      onEditPDF(fileLink)
    }
  }

  const handleEditPDF = (site) => {
    const fileLink = getDirectFileLinkForSite(site)
    handleEditPdfWithPSWD(site?.password, fileLink)
  }

  const handleEditHTML = (site) => {
    const domain = getDomain(site)
    onEditHTML(domain, !hasFeature(userProfile.productId, F_EDIT_MODE))
  }

  const onUpgrade = (planId) => {
    dispatch(setShowPaymentModal({ showPaymentModal: true, upgradePlanId: planId }))
  }

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  const filteredData = useMemo(() => {
    return allSites.filter((site) => getDomain(site).toLowerCase().includes(searchTerm.toLowerCase()))
  }, [allSites, searchTerm])

  const headers = [
    { key: 'status', text: 'Status', icon: '', cellType: 'status' },
    { key: 'domain', text: 'Project', icon: '', cellType: 'domain' },
    { key: 'lastModified', text: 'Modified', icon: '', cellType: 'lastModified' },
    { key: 'actions', text: 'Actions', icon: '', cellType: 'actions' }
  ]

  const data = filteredData.map((s) => ({
    status: {
      status: s?.linkStatus,
      text: s?.linkStatus === 'processing' ? 'Processing' : s.linkStatus === 'error' ? 'Failed' : 'Active',
      passwordProtected: s.passwordProtected
    },
    domain: {
      label: getDomain(s),
      href: `https://${getDomain(s)}`,
      site: s,
      handlePreviewClick: onPreview,
      disabled: s?.linkStatus === 'processing' || s?.linkStatus === 'error'
    },
    lastModified: s?.lastUpdated || s?.created ? moment(s.lastUpdated || s.created).format('DD MMM YY') : '-',
    actions:
      isMobile || isTablet
        ? [
            {
              type: 'action_menu',
              props: {
                icon: '/assets/icons/menu_horizontal.svg',
                tooltip: 'More',
                onEditHTML: () => handleEditHTML(s),
                onEditPDF: () => handleEditPDF(s),
                onArchive: () => onArchive(s),
                onDownload: () => onDownload(s),
                onAnalytics: () => onAnalytics(s),
                onQRCode: () => onQRCode(s),
                onDelete: () => onDelete(s),
                onCopy: () => copyToClipboard(`https://${getDomain(s)}`),
                onReplaceFile: () => handleUpdateClick(s),
                onEmbed: () => onEmbed(s),
                onAddCustomDomain: () => onAddCustomDomain(),
                link: getDomain(s),
                type: s.type,
                passwordProtected: s?.passwordProtected,
                enableEmailGate: s?.enableEmailGate,
                linkPreview: s?.linkPreview,
                onShare: () => onShare(s),
                trial: !!!userProfile?.productId,
                disabled: s?.linkStatus === 'processing' || s?.linkStatus === 'error',
                onUpgrade: () => onUpgrade(PLANS.TINY.id),
                table: 'livesites'
              }
            }
          ]
        : [
            {
              type: 'custom_button',
              component: (
                <UpdateActionDropdown
                  site={s}
                  onEditPDF={handleEditPDF}
                  onEditHTML={handleEditHTML}
                  onReplaceFile={handleUpdateClick}
                />
              ),
              label: 'Update'
            },
            { icon: '/assets/icons/chart-bar.svg', tooltip: 'Analytics', onClick: () => onAnalytics(s) },
            {
              icon: '/assets/icons/copy-link.svg',
              tooltip: 'Copy',
              onClick: () => copyToClipboard(`https://${getDomain(s)}`)
            },
            {
              type: 'action_menu',
              props: {
                icon: '/assets/icons/menu.svg',
                tooltip: 'More',
                onEditHTML: () => handleEditHTML(s),
                onEditPDF: () => handleEditPDF(s),
                onArchive: () => onArchive(s),
                onDownload: () => onDownload(s),
                onAnalytics: () => onAnalytics(s),
                onQRCode: () => onQRCode(s),
                onDelete: () => onDelete(s),
                onCopy: () => copyToClipboard(`https://${getDomain(s)}`),
                onReplaceFile: () => handleUpdateClick(s),
                onEmbed: () => onEmbed(s),
                onAddCustomDomain: () => onAddCustomDomain(),
                link: getDomain(s),
                type: s?.type,
                passwordProtected: s?.passwordProtected,
                enableEmailGate: s?.enableEmailGate,
                linkPreview: s?.linkPreview,
                onShare: () => onShare(s),
                trial: !!!userProfile?.productId,
                disabled: s?.linkStatus === 'processing',
                onUpgrade: () => onUpgrade(PLANS.TINY.id)
              }
            }
          ]
  }))

  const headerButtons = [
    {
      type: 'custom_button',
      component: (
        <UploadDropdown
          onSiteAction={onSiteAction}
          onShowCodeEditor={onShowCodeEditor}
          onOpenTextEditor={onOpenTextEditor}
        />
      )
    }
    // Add more buttons
  ]

  return (
    <div className={styles.table}>
      <CustomTable
        headers={headers}
        data={data}
        title="Live Projects"
        badge={badge}
        headerButtons={headerButtons}
        emptyStateMessage="No live projects"
        emptyStateButton={{ text: 'Upload file', onClick: () => onSiteAction('CREATE') }}
        onSearch={handleSearch}
        searchTerm={searchTerm}
        showSearch={true}
      />
    </div>
  )
}

export default LiveSitesTable

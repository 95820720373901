import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'

const OnBoardingSurvey = ({ onContinue, status }) => (
  <Formik onSubmit={onContinue} initialValues={{}}>
    {({ handleSubmit, values, handleChange }) => (
      <Form onSubmit={handleSubmit}>
        <h3 className="mb-2 color-purple">Let's improve your experience</h3>
        <p>Please tell us why you’re using tiiny.host.</p>
        <Form.Group>
          <Form.Label className="bold">What are you using Tiiny for?</Form.Label>
          <Form.Control name="useCase" as="select" value={values.dUseCase} onChange={handleChange}>
            <option value="">Select...</option>
            <option value="Marketing">Sharing marketing material</option>
            <option value="Resume">Sharing resume/CV</option>
            <option value="Personal website/project">Personal website/project</option>
            <option value="Marketing">Sharing informational material</option>
            <option value="Prototyping">Prototyping</option>
            <option value="App-Testing">Testing</option>
            <option value="Client-Demo">Client demo</option>
            <option value="Learning">Learning</option>
            <option value="Marzipano">Marzipano</option>
            <option value="In5">In5 Hosting</option>
            <option value="Other">Other</option>
          </Form.Control>
          <Form.Control
            name="other"
            as="textarea"
            className="mt-2"
            placeholder="Anything specific to add? (optional)"
            value={values.dUseCase}
            onChange={handleChange}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" disabled={status.loading} className="mt-3 float-right tr-onboarding-questionnaire-submit">
          {status.loading ? <Spinner animation="border" size="sm" /> : 'Complete'}
        </Button>
      </Form>
    )}
  </Formik>
)

export default OnBoardingSurvey

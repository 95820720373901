import moment from 'moment'

// <script rel="preload" defer src="https://tiiny.host/add-to-cal-dev/static/js/main.js"></script>
// <link href="https://tiiny.host/add-to-cal-dev/static/css/main.css" rel="stylesheet"> -->

export function getAddToCalHTML({ title = '', host = '', location = '', description = '', allDay, start, end }) {
  let dateStr = ''
  if (allDay) {
    dateStr = `${moment(start).format('DD MMM YYYY')} (All day event)`
  } else {
    dateStr =
      moment(start).format('DD-MM-YYYY') === moment(end).format('DD-MM-YYYY')
        ? `${moment(start).format('DD MMM YYYY ⋅ HH:mm')} - ${moment(end).format('HH:mm')}`
        : `${moment(start).format('DD MMM YYYY - HH:mm')} - ${moment(end).format('DD MMM YYYY - HH:mm')}`
  }

  return `<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <link rel="icon" href="https://tiiny.host/add-to-cal/favicon.ico" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />
  <meta name="theme-color" content="#000000" />
  <meta name="description" content="${location} ⋅ ${dateStr}" />
  
  <meta property="og:type" content="website">
  <meta property="og:url" content="https://tiiny.host/">
  <meta property="og:title" content="Invitation to ${title} by ${host}" data-react-helmet="true">
  <meta property="og:image" content="https://tiiny.host/add-to-cal-lib/og.png">
  <meta property="og:description" content="${location} ⋅ ${dateStr}" data-react-helmet="true">
  
  <title>Invitation to ${title} by ${host}</title>
  <script rel="preload" defer src="https://tiiny.host/add-to-cal-lib/static/js/main.js"></script>
  <link href="https://tiiny.host/add-to-cal-lib/static/css/main.css" rel="stylesheet">

  <script>
    window.addToCalData = ${JSON.stringify({ title, host, location, description, allDay, start, end })}
  </script>
</head>

<body>
  <noscript>You need to enable JavaScript to run this app.</noscript>
  <div id="root"></div>
</body>

</html>`
}
